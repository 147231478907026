<template>
    <div>
        <div class="nav-offset"></div>
        <div class="policy py-5 mb-5">
            <div class="container">
                <div v-for="policy in policyListing" :key="policy.id">
                    <h4 class="mega-title mb-4">{{ policy.megaTitle }}</h4>
                    <p class="mega-description" v-html="policy.megaDescription" />
                    <ul class="pl-3">
                        <li v-for="listing in policy.listing" :key="listing.num">
                            <b><p v-html="listing.caption" /></b>
                            <p v-html="listing.description1" />
                            <ol >
                                <li v-for="pointer in listing.pointer" :key="pointer.point">
                                    <p v-html="pointer.text" />
                                </li>
                            </ol>
                            <p v-html="listing.description2" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="py-5 py-lg-5"></div>
    </div>
</template>

<script>
export default {
  name: 'policy',
  data () {
    return {
      policyListing: []
    }
  },
  mounted () {
    this.fetchData(this.$i18n.locale)
  },
  methods: {
    async fetchData () {
      const res = await fetch('../data-lists/policy.json')
      const val = await res.json()
      this.policyListing = val
    }
  }
}
</script>

<style>

</style>
