<template>
    <div>
        <div v-for="bio in $t('management-team.bio')" :key="bio.id">
            <b-container fluid :class="bio.bg">
                <b-container>
                    <div class="pt-5">
                        <b-row class="flex-warp flex-column flex-sm-row">
                            <b-col class="order-2 order-sm-1 profile-image" :class="bio.imgPosition">
                                <img :src="require('@/'+bio.partner.img)" alt="">
                            </b-col>
                            <b-col class="order-1 order-sm-1 px-md-5 py-3 py-lg-5 profile-details">
                                <h4 class="name">{{ bio.partner.name }}<a :href="bio.linkedin.path" target="_blank" class=" d-none d-md-inline-block"><fa-icon class="ml-2" :icon="[bio.linkedin.type, bio.linkedin.name]" :color="bio.linkedin.color"/></a></h4>
                                <h5 class="title  mb-4">{{ bio.partner.title }}<a :href="bio.linkedin.path" target="_blank" class="d-md-none"><fa-icon class="ml-2" :icon="[bio.linkedin.type, bio.linkedin.name]" :color="bio.linkedin.color"/></a></h5>
                                <p v-html="bio.partner.desc1" class="desc"></p>
                                <p v-html="bio.partner.desc2" class="desc"></p>
                            </b-col>
                        </b-row>
                    </div>
                </b-container>
            </b-container>
        </div>
    </div>
</template>

<script>
export default {
  name: 'our-team',
  data () {
    return {
      teamListing: []
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
