<template>
    <div>
        <b-col>
          <b-card
            :title="name"
            :img-src="require(`@/assets/images/hero-banner/${path}`)"
            img-alt="Job-info"
            img-top
            tag="article"
            style="max-width:20rem"
            class="mb-2"
            footer="Card Footer">
            <b-card-text>
              This is Card Text.
            </b-card-text>
            <b-button variant="primary">Apply</b-button>
          </b-card>
        </b-col>
    </div>
</template>

<script>
export default {
  props: [
    'name',
    'path'
  ]
}
</script>

<style lang="scss" scoped>

</style>
