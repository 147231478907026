<template>
  <div class="home">
    <hero-banner
      :bgPath="`assets/images/hero-banner/Flying_kid2.png`"
      :bgPathMobile="`assets/images/hero-banner/flying-kid-mobile.png`"
      height="800"
      horizontal="left"
      dVertical="d-center"
      mVertical="m-top"
      mHorizontal="m-top"
      :title="$t('home.banner.title')"
      :description="$t('home.banner.description')"
      contentCol="8"
      cta="true"
    ></hero-banner>

    <!-- Explore Expertise -->
    <container-style direction="style-right" bgColor="bg-white" :bgPath="null">
      <div class="container">
        <b-row>
          <b-col class="d-flex justify-content-lg-center"><tag content="Explore Expertise"></tag></b-col>
        </b-row>
        <b-row>
          <b-col>
            <header-tile
              class="mt-4"
              className="sub-title"
              textAlign="lg-center"
              content="Discover Professional <span class=&quot;text-nowrap&quot;>End-to-End</span> <br> Secretarial & Tax Solutions for <span class=&quot;text-nowrap&quot;>Your SME</span>"
              >
            </header-tile>
          </b-col>
        </b-row>
          <b-row class="mt-0 mt-lg-4 no-gutters">
            <expertise-swiper></expertise-swiper>
          </b-row>
          <b-row class="no-gutters">
            <certificate></certificate>
          </b-row>
        </div>
    </container-style>

    <!-- Explore Services -->
    <container-style direction="style-left" bgColor="bg-white" :bgPath="computedServiceBackgroundImage">
        <quick-links class="py-5" style="display: none;"></quick-links>
        <div class="container">
          <b-row class="flex-column flex-lg-row">
            <b-col class="text-white">
              <tag content="Explore Services"></tag>
              <header-tile
                class="mt-4"
                className="sub-title"
                content="Comprehensive Secretarial & Tax Solutions">
              </header-tile>
              <div class="mt-4">Whether you want to get incorporated, plan taxes for your brand-new business, or try to ensure your payroll is compliant, our talented team of certified experts is committed to offering the solutions you need in one place. </div>
            </b-col>
            <b-col class="mt-5 mt-lg-0">
              <services-box></services-box>
            </b-col>
          </b-row>
        </div>
    </container-style>

    <!-- Explore Capabilities -->
    <container-style direction="style-right" bgColor="bg-white" :bgPath="null">
      <b-container>
        <b-row class="no-gutters">
          <tag content="Explore Capabilities"></tag>
        </b-row>
        <b-row class="no-gutters">
          <div class="mt-4">
            <header-tile
              className="sub-title"
              content="Let Us Worry About Compliance While You Grow Your Business">
            </header-tile>
            <p>Clients across multiple industry sectors rely on us to deliver solutions that future-proof their businesses.</p>
          </div>
        </b-row>
      </b-container>
      <capabilities-slider></capabilities-slider>
    </container-style>

    <!-- Explore Information -->
    <container-style id="youtube-gallery" direction="style-left" bgColor="bg-earth" :bgPath="null">
      <div class="container">
        <b-row>
          <b-col><tag content="Explore Information"></tag></b-col>
        </b-row>
        <b-row>
          <b-col>
            <header-tile
              class="mt-4"
              className="sub-title"
              content="SMEs' Essentials at Your Fingertips">
            </header-tile>
          </b-col>
        </b-row>
        <youtube-gallery class="mt-4"></youtube-gallery>
      </div>
    </container-style>

    <container-style direction="style-right" bgColor="bg-white" :bgPath="null">
      <b-container>
        <b-row class="career-card flex-column flex-lg-row">
          <b-col class="no-gutters d-lg-none mb-4">
              <tag content="Explore Career"></tag>
          </b-col>
          <b-col class="col-lg-4">
            <div class="card-bg" :style="{'background-image': 'url(' + require('@/assets/images/home/homepage_career.png') + ')'}"></div>
          </b-col>
          <b-col class="col-lg-8">
            <b-row class="no-gutters d-none d-lg-flex">
              <tag content="Explore Career"></tag>
            </b-row>
            <b-row class="no-gutters">
              <header-tile class="mt-4" className="sub-title" content="Your Next Career Awaits"></header-tile>
              <p>Whether you are starting out in your career or just needing a change, we have various opportunities for you.</p>
              <p>At Tyng & Co, we are a different kind of firm. One that loves to collaborate and innovate. One that possesses a sincere desire to offer an environment where you can belong and excel. We believe our growth and the growth of our people go hand in hand.</p>
              <p>All you need to do is email your CV to us at <a class="text-nowrap" href="mailto:service@tyng-ca.com.my">service@tyng-ca.com.my</a> and we will read it and either get back to you, or keep it on file until a suitable role becomes available.</p>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </container-style>

    <!-- <font-awesome-icon :icon="['fas', 'face-smile-beam']" /> -->

    <!-- <div class="hero-banner">
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide class="tile">
          <img src="../assets/images/hero-banner/hanging-open.png" alt="">
          <div class="bg" :style="{'background-image': 'url(' + require('@/assets/images/hero-banner/flying-kid.png') + ')'}"></div>
          <div class="container d-flex relative h-100">
            <div class="col-6">
              <div class="content px-5">
                <h1 v-html="$t('home.banner.title')"/>
                <b-btn  :to="`/${$i18n.locale}/why-us`" class="btn-text-charcoal mt-lg-4">
                  <b>{{ $t('home.banner.cta') }}</b>
                  <fa-icon :icon="['fas', 'chevron-right']" color="gold" class="ml-2"/>
                  </b-btn>
                <div :style="{backgroundImage: 'url(' + bg2 + ')' }"></div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>-->
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import JobCard from '@/components/jobCard.vue'
import quickLinks from '@/components/quickLinksCarousal.vue'
import heroBanner from '@/components/heroBanner.vue'
import containerStyle from '@/components/containerStyle.vue'
import expertiseSwiper from '../components/expertiseSwiper.vue'
import youtubeGallery from '../components/youtubeGallery.vue'
import tag from '../components/tag.vue'
import servicesBox from '../components/servicesBox.vue'
import headerTile from '../components/headerTile.vue'
import capabilitiesSlider from '../components/capabilitiesSlider.vue'
import certificate from '../components/certificate.vue'
// import FeatureTiles from '@/components/featureTiles.vue'
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
// import Bg2 from '@/assets/hero-banner/hanging-open.png'

export default {
  name: 'Home',
  metaInfo () {
    const title = 'Tyng & Co | Home'
    const description = 'Stress-Free Compliance for Businesses'
    document.querySelector('meta[name="description"]').content = description
    return {
      title,
      titleTemplate: null,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: description
        }
      ],
      link: [{
        rel: 'canonical',
        href: 'https://tyng-ca.com.my'
      }]
    }
  },
  components: {
    // 'job-card': JobCard,
    'quick-links': quickLinks,
    'hero-banner': heroBanner,
    'container-style': containerStyle,
    'expertise-swiper': expertiseSwiper,
    'youtube-gallery': youtubeGallery,
    tag,
    'services-box': servicesBox,
    'header-tile': headerTile,
    'capabilities-slider': capabilitiesSlider,
    certificate
    // Swiper,
    // SwiperSlide
  },
  mounted () {
    this.$nextTick(() => {
      this.fetchData()
      const to = this.$route.query.to
      if (to && to === 'youtube-gallery') {
        const element = document.getElementById('youtube-gallery')
        if (element) {
          const elementPosition = element.getBoundingClientRect().top + window.pageYOffset
          window.scrollTo({
            top: elementPosition + 200, // Subtract 50px for the offset
            behavior: 'smooth'
          })
        }
      }
      this.checkDevice()
      window.addEventListener('resize', this.checkDevice)
    })
  },
  data () {
    return {
      jobs: [],
      displayJobs: [],
      currentPage: 1,
      rows: 1,
      perPage: 3,
      isDesktop: window.innerWidth >= 992
      //  bg2: Bg2
    }
  },
  methods: {
    checkDevice () {
      this.isDesktop = window.innerWidth >= 992
    },
    async fetchData () {
      const res = await fetch('jobs.json')
      const val = await res.json()
      this.jobs = val
      this.displayJobs = val.slice(0, 3)
      this.rows = this.jobs.length
      // console.log(val)
    },
    paginate (currentPage) {
      const start = (this.currentPage - 1) * this.perPage
      this.displayJobs = this.jobs.slice(start, start + 3)
    }
  },
  computed: {
    computedServiceBackgroundImage () {
      return this.isDesktop
        ? 'assets/images/home/Services_connecting2.png'
        : 'assets/images/home/Services_connecting_mobile.png'
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.checkDevice)
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.quick-links {
  min-height: 500px;
}

.welcome {
  font-size: 46px;
}
.swiper {
  height: 600px;
}

.slider-container {
  width: 100%;
  padding: 20px;
}

.tab-navigation {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  position: relative;
  border-bottom: 1px solid #e5e7eb;
}

.tab-item {
  position: relative;
  padding: 10px 0;
  cursor: pointer;
  font-weight: bold;
  color: #666;
  transition: color 0.3s;
}

.tab-item.active {
  color: #000;
}

.tab-underline {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #000;
}

.slide-content {
  padding: 20px 0;
}

.swiper-button-next,
.swiper-button-prev {
  color: #666;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 24px;
}

/* Explore Services */

.explore-services {
  display: flex;
  width: 100%;
  .content {
    width: 50%;
  }
}

.career-card {
  .card-bg {
    width: 100%;
    min-height: 240px;
    border-radius: 20px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    @include media-breakpoint-up(lg) {
      min-height: 320px;
    }
  }
  a {
    color: darkgoldenrod;
    &:hover {
      color: rgb(148, 109, 10);
    }
  }
}

</style>
