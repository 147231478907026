import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import './plugins/swiper-vue'
import './plugins/youtube'
import './plugins/fontawesome-vue'
import './plugins/multiselect-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import JQuery from 'jquery'
import './assets/css/font-embed.css'
import './assets/scss/base.scss'
import Vuelidate from 'vuelidate'
import axios from 'axios'
import VueAxios from 'vue-axios'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueMeta from 'vue-meta'

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  waitOnDestroyed: true, // Add this
  debounceWait: 50 // Add this
})
Vue.use(VueAxios, axios)
Vue.use(Vuelidate)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDbo09mFY7tdDxtgtEwDAiq5xtV9rABfic',
    libraries: 'places'
  }
})

global.$ = JQuery
// $('body').css('background-color', 'orange')
// Here you can add the code for different plugins

Vue.config.productionTip = false

// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {
  // use the language from the routing param or default languange
  let language = to.params.lang
  if (!language) {
    language = 'en'
  }

  $('html, body').animate({
    scrollTop: top
  }, 0)

  // set the current language for i18n.
  i18n.locale = language
  next()
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
