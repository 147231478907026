<template>
  <div class="services">
    <hero-banner
    :bgPath="`assets/images/hero-banner/throw.png`"
    :bgPathMobile="`assets/images/hero-banner/throw-mobile.png`"
    height="800"
    horizontal="left"
    dVertical="d-center"
    mVertical="m-top"
    textAlign="text-center text-md-left"
    color=""
    contentCol="8"
    :title="$t('services.banner.title')"
    cta="false">
    </hero-banner>
      <!-- <div class="nav-offset"></div> -->
      <!-- <div class="hero-banner">
        <swiper class="swiper h-100">
        <swiper-slide class="tile">
          <div class="bg" :style="{'background-image': 'url(' + require('@/assets/images/hero-banner/wow.png') + ')'}"></div>
          <div class="container d-flex relative h-100">
            <div class="col-6">
              <div class="content px-5">
                <h1 v-html="$t('services.banner.title')"/>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      </div> -->
      <container-style id="services-type" direction="style-right" bgColor="bg-white" :bgPath="null">
        <div class="container">
        <div>
          <feature-tiles></feature-tiles>
        </div>
      </div>
      </container-style>
      <container-style id="step-container" class="position-relative custom-container-bg-for-step" direction="style-left" bgColor="bg-white" :bgPath="computedBackgroundStyle">
        <div style="position: absolute; left: 0; top: 0; background: linear-gradient(to bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0)); width: 100%; height: 100%;"></div>
        <b-container>
          <b-row>
            <b-col class="mt-4 mt-lg-0 order-2 order-lg-1">
              <div class="step-box">
                <div class="incorporation-steps">
                  <div class="step">
                    <div class="step-number">1</div>
                    <div class="step-content">Fill Out the Incorporation Form</div>
                  </div>
                  <div class="step">
                    <div class="step-number">2</div>
                    <div class="step-content">Pay the Incorporation Fee</div>
                  </div>
                  <div class="step">
                    <div class="step-number">3</div>
                    <div class="step-content">Complete the Identity Verification Process (e-KYC)
                    </div>
                  </div>
                  <div class="step">
                    <div class="step-number">4</div>
                    <div class="step-content">Sign the Incorporation Documents When We Send Them
                    </div>
                  </div>
                  <!-- Repeat for other steps -->
                </div>
              </div>
              <div class="icon-down d-flex d-md-none mt-4 justify-content-center"><fa-icon :icon="['fas', 'chevron-circle-down']" class="fa-2x" style="cursor: pointer;" color:="darkgary"  @click="scrollToFooter"/></div>
            </b-col>
            <b-col class="order-1 order-lg-2">
              <header-tile content="Streamline Incorporation in 4 Easy, Online Steps"></header-tile>
              <hr>
              <p class="font-semiBold fa-1x" :class="this.isDesktop ? 'fa-md-18' : '' ">Tyng & Co takes the stress out of incorporation, handling everything and ensuring accuracy while you relax knowing that your business will be incorporated in only 2-5 working days.</p>
              <p class="font-semiBold fa-1x" :class="this.isDesktop ? 'fa-md-18' : '' ">To further simplify the process, we offer 1 point of contact for guidance throughout each of the 4 steps, even giving you the optional opportunity to use our trusted banking provider. </p>
              <div class="icon-down d-none d-md-flex justify-content-center"><fa-icon :icon="['fas', 'chevron-circle-down']" class="fa-2x" style="cursor: pointer;" color:="darkgary"  @click="scrollToFooter"/></div>
            </b-col>
            <div class="w-100 d-lg-none order-3" style="height:400px"></div>
          </b-row>
        </b-container>
      </container-style>
  </div>
</template>

<script>
import FeatureTiles from '@/components/featureTiles.vue'
import heroBanner from '@/components/heroBanner.vue'
import containerStyle from '../components/containerStyle.vue'
import headerTile from '../components/headerTile.vue'

export default {
  name: 'services',
  metaInfo () {
    const type = this.$route.query.type
    const title = 'Tyng & Co | Services'
    const descriptions = 'Streamline Company Registration in 4 Easy, Online Steps'

    document.querySelector('meta[name="description"]').content = descriptions

    return {
      title,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: descriptions
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: `https://tyng-ca.com.my/${this.$i18n.locale}/services?type=${type}`
        }
      ]
    }
  },
  components: {
    'feature-tiles': FeatureTiles,
    'hero-banner': heroBanner,
    'container-style': containerStyle,
    'header-tile': headerTile
  },
  data () {
    return {
      isDesktop: window.innerWidth >= 992
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.checkDevice()
      window.addEventListener('resize', this.checkDevice)
      const type = this.$route.query.type
      if (!type) return
      const element = document.getElementById('services-type')
      if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset
        window.scrollTo({
          top: elementPosition - 100, // Subtract 50px for the offset
          behavior: 'smooth'
        })
      } else {
        console.error('Element with id "services-type" not found.')
      }
    })
  },
  methods: {
    checkDevice () {
      this.isDesktop = window.innerWidth >= 992
      console.log('this.isDesktop == ', this.isDesktop)
    },
    scrollToFooter () {
      window.scrollTo({
        top: document.documentElement.scrollHeight, // Scroll to the very bottom
        behavior: 'smooth' // Smooth scrolling
      })
    }
  },
  computed: {
    computedBackgroundStyle () {
      if (this.isDesktop) {
        return 'assets/images/services/moving_forward.png'
      } else {
        return 'assets/images/services/moving_forward_mobile.png'
      }
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.checkDevice)
  }
}
</script>

<style lang="scss" scoped>
</style>
