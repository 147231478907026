<template>
  <div class="deadline">
    <hero-banner :bgPath="null" :bgPathMobile="`assets/images/hero-banner/deadlines-mobile.png`" height="800" horizontal="center" dVertical="d-center"
      mVertical="m-top" textAlign="text-left text-md-left" color="" contentCol="12"
      :title="$t('deadline.banner.title')" :description="$t('deadline.banner.description')" cta="false">
    </hero-banner>
    <container-style class="position-relative" direction="style-right" bgColor="bg-white" :bgPath="null">
        <div class="bg-top-gray-to-white d-flex position-absolute" style="left: 0; top: 0; width: 100%; height: 200px; background: linear-gradient(0, rgb(255, 255, 255) 0%, rgb(200 200 200) 100%);"></div>
        <div class="container">
          <b-row class="px-md-5">
            <b-col class="col-12 col-lg-9">
              <!-- <b-row>
                <b-col class="col-5 offset-5">
                  <div style="
                      /* font-size: 14px; */
                      font-weight: 500;
                      border-radius: 6px;
                      border: 1px solid gray;
                      padding: 0 8px;
                      display: inline-block;
                      margin-bottom: 8px;
                  ">Optional
                  </div>
                </b-col>
              </b-row> -->
              <b-row class="flex-column flex-lg-row">
                <b-col class="col-lg-5">
                  <div class="custom-dropdown">
                    <div class="form-group">
                      <label class="form-label">Select Financial Year End : <fa-icon id="yearend-date-tooltip" class="icon-info ml-2" :icon="['fas', 'info-circle']" /></label>
                        <b-row>
                          <b-col>
                            <div class="d-flex align-items-center">
                              <multiselect class="form-control form-select" v-model="yearEndVal" :options="months" label="name"
                                @input="onYearEndChange" :searchable="false" :close-on-select="true" :allow-empty="false">
                                <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}<fa-icon
                                    :icon="['fas', 'chevron-down']" color="darkgoldenrod" class="arrow-down" /></template>
                              </multiselect>
                              <div>
                                <b-tooltip class="custom-tooltip" target="yearend-date-tooltip">
                                  <p class="fa-xs fa-lg-sm"><b>In which month does the company close its annual accounts?</b></p>
                                  <p class="fa-xs fa-lg-sm">For Sdn Bhd / LLP, please check with your accountant / company secretary if you're unsure.</p>
                                  <div class="fa-xs fa-lg-sm">For sole prop / partnership / individuals, please select December.</div>
                                </b-tooltip>
                                <!-- <fa-icon id="yearend-date-tooltip" class="icon-info ml-2" :icon="['fas', 'info-circle']" /> -->
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                    </div>
                  </div>
                </b-col>
                <b-col class="mt-4 mt-lg-0 d-flex justify-content-end">
                  <div class="custom-dropdown">
                    <div class="form-group">
                      <label class="form-label">Select Incorporation Anniversary Date <br class="d-lg-none">(Optional) :  <fa-icon id="anniversary-date-tooltip" class="icon-info ml-2" :icon="['fas', 'info-circle']" /></label>
                      <b-row>
                        <b-col class="">
                          <div class="d-flex justify-content-lg-end">
                            <multiselect class="form-control form-select" v-model="anniversaryMonthVal" :options="months"
                              label="name" @input="onMonthChange" :searchable="false" :close-on-select="true" :allow-empty="false">
                              <template slot="singleLabel" slot-scope="{ option }">{{ option.val ? option.name : 'Month' }}<fa-icon
                                  :icon="['fas', 'chevron-down']" color="darkgoldenrod" class="arrow-down" /></template>
                            </multiselect>
                        </div>
                        </b-col>
                        <b-col class="mt-2 mt-lg-0">
                          <div class="d-flex justify-content-lg-end">
                            <multiselect class="form-control form-select" v-model="anniversarydayVal" :options="filteredDays"
                              label="name" @input="onDayChange" :searchable="false" :close-on-select="true" :allow-empty="false"
                              placeholder="day" :disabled="isDaySelectDisabled">
                              <template slot="singleLabel" slot-scope="{ option }">{{ option.val ? option.name : 'Day' }}<fa-icon
                                  :icon="['fas', 'chevron-down']" color="darkgoldenrod" class="arrow-down" /></template>
                            </multiselect>
                            <div>
                              <b-tooltip class="custom-tooltip" target="anniversary-date-tooltip">
                                <p class="fa-xs fa-lg-sm"><b>This field is only required for Sdn Bhd companies.</b></p>
                                <p class="fa-xs fa-lg-sm"><b>For example, if you register your company with SSM on 13/08/2020, please select the month as "August" and the day as "13". The year "2020" is not relevant.</b></p>
                                <p class="fa-xs fa-lg-sm">Your company's incorporation anniversary date will determine the deadline to file the SSM Annual Return.</p>
                                <p class="fa-xs fa-lg-sm">If the incorporation anniversary date is not provided, the deadline for the SSM Annual Return will not be displayed.</p>
                                <div class="fa-xs fa-lg-sm">However, don't worry — we will still show you all other important deadlines (just not this one).</div>
                              </b-tooltip>
                              <!-- <fa-icon id="anniversary-date-tooltip" class="icon-info ml-2" :icon="['fas', 'info-circle']" /> -->
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row class="d-none">
                        <b-col>
                          <div class="custom-checkbox-wrapper mt-2">
                            <label class="custom-checkbox">
                              <input type="checkbox" v-model="isChecked" @change="handleChange" />
                              <span class="checkmark"></span>
                              <span class="label-text">please check</span>
                            </label>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>

        <div class="container">
          <b-row class="px-md-5">
            <b-col class="col-lg-9 video-btn-wrapper">
              <router-link class="video-btn" :to="`/${$i18n.locale}?to=youtube-gallery`">Watch information videos<fa-icon id="anniversary-date-tooltip" class="ml-2" :icon="['fas', 'chevron-circle-right']" /></router-link>
            </b-col>
          </b-row>
        </div>

         <!-- Calender Swiper -->
        <div class="calendar-section-bg position-relative mt-lg-0 pt-lg-0" :style="computedBackgroundStyle">
          <div class="clenddar-section-strap-bg"></div>
          <div class="container">
            <b-row class="px-md-5">
              <b-col class="col-lg-9">
                <!-- Desktop Setting -->
                <swiper class="deadline-carousel d-none d-lg-block" :options="swiperOption">
                  <swiper-slide>
                    <div class="calender-grid-container">
                      <div v-for="(eachMonth, index) in newList.slice(0, 4)" :key="index" class="grid-item">
                        <div class="month-bg-style">
                            {{ getMonthCapital(eachMonth.month) }}
                        </div>
                        <div class="triangle-bg"></div>
                        <div class="triangle-bg-two"></div>
                        <div class="calender-detail-wrapper">
                          <b-row class="no-gutters calender-content">
                            <div class="calender-tag">
                              ALL BUSINESSES
                            </div>
                            <div class="pl-2">
                              <b-row v-for="(business, index) in eachMonth.allBusiness" :key="index">
                                <b-col class="calender-body col-2">
                                  {{ business.date }}
                                </b-col>
                                <b-col>
                                  <div class="d-inline-block">
                                    <span v-html="business.name"></span>
                                    <fa-icon v-if="business.dot" class="mt-1 ml-1" :icon="['fas', 'circle']" :color="business.dot.color" />
                                    <fa-icon v-if="business.dotExtend" class="mt-1 ml-1" :icon="['fas', 'circle']" :color="business.dotExtend.color" />
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                          </b-row>
                          <b-row class="no-gutters calender-content">
                            <div class="calender-tag">
                              SDN BHD / LLP
                            </div>
                            <div class="pl-2">
                              <b-row v-for="(sdnbhdLlp, index) in eachMonth.sdnbhdLlp" :key="index">
                                <b-col class="calender-body col-2">
                                  {{ sdnbhdLlp.date }}
                                </b-col>
                                <b-col>
                                  <div class="d-inline-block">
                                    <span v-html="sdnbhdLlp.name"></span>
                                    <fa-icon v-if="sdnbhdLlp.dot" class="mt-1 ml-1" :icon="['fas', 'circle']" :color="sdnbhdLlp.dot.color" />
                                    <fa-icon v-if="sdnbhdLlp.dotExtend" class="mt-1 ml-1" :icon="['fas', 'circle']" :color="sdnbhdLlp.dotExtend.color" />
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                          </b-row>
                          <b-row v-if="eachMonth.solePropPartner.length" class="no-gutters calender-content">
                            <div class="calender-tag">
                              SOLE PROP / PARTNERSHIP / INDIVIDUALS
                            </div>
                            <div class="pl-2">
                              <b-row v-for="(solePropPartner, index) in eachMonth.solePropPartner" :key="index">
                                <b-col class="calender-body col-2">
                                  {{ solePropPartner.date }}
                                </b-col>
                                <b-col>
                                  <div class="d-inline-block">
                                    <span v-html="solePropPartner.name"></span>
                                    <fa-icon v-if="solePropPartner.dot" class="mt-1 ml-1" :icon="['fas', 'circle']" :color="solePropPartner.dot.color" />
                                    <fa-icon v-if="solePropPartner.dotExtend" class="mt-1 ml-1" :icon="['fas', 'circle']" :color="solePropPartner.dotExtend.color" />
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                          </b-row>
                        </div>
                      </div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="calender-grid-container">
                      <div v-for="(eachMonth, index) in newList.slice(4, 8)" :key="index" class="grid-item">
                        <div class="month-bg-style">
                            {{ getMonthCapital(eachMonth.month) }}
                        </div>
                        <div class="triangle-bg"></div>
                        <div class="triangle-bg-two"></div>
                        <div class="calender-detail-wrapper">
                          <b-row class="no-gutters calender-content">
                            <div class="calender-tag">
                              ALL BUSINESSES
                            </div>
                            <div class="pl-2">
                              <b-row v-for="(business, index) in eachMonth.allBusiness" :key="index">
                                <b-col class="calender-body col-2">
                                  {{ business.date }}
                                </b-col>
                                <b-col>
                                  <div class="d-inline-block">
                                    <span v-html="business.name"></span>
                                    <fa-icon v-if="business.dot" class="mt-1 ml-1" :icon="['fas', 'circle']" :color="business.dot.color" />
                                    <fa-icon v-if="business.dotExtend" class="mt-1 ml-1" :icon="['fas', 'circle']" :color="business.dotExtend.color" />
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                          </b-row>
                          <b-row class="no-gutters calender-content">
                            <div class="calender-tag">
                              SDN BHD / LLP
                            </div>
                            <div class="pl-2">
                              <b-row v-for="(sdnbhdLlp, index) in eachMonth.sdnbhdLlp" :key="index">
                                <b-col class="calender-body col-2">
                                  {{ sdnbhdLlp.date }}
                                </b-col>
                                <b-col>
                                  <div class="d-inline-block">
                                    <span v-html="sdnbhdLlp.name"></span>
                                    <fa-icon v-if="sdnbhdLlp.dot" class="mt-1 ml-1" :icon="['fas', 'circle']" :color="sdnbhdLlp.dot.color" />
                                    <fa-icon v-if="sdnbhdLlp.dotExtend" class="mt-1 ml-1" :icon="['fas', 'circle']" :color="sdnbhdLlp.dotExtend.color" />
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                          </b-row>
                          <b-row v-if="eachMonth.solePropPartner.length" class="no-gutters calender-content">
                            <div class="calender-tag">
                              SOLE PROP / PARTNERSHIP / INDIVIDUALS
                            </div>
                            <div class="pl-2">
                              <b-row v-for="(solePropPartner, index) in eachMonth.solePropPartner" :key="index">
                                <b-col class="calender-body col-2">
                                  {{ solePropPartner.date }}
                                </b-col>
                                <b-col>
                                  <div class="d-inline-block">
                                    <span v-html="solePropPartner.name"></span>
                                    <fa-icon v-if="solePropPartner.dot" class="mt-1 ml-1" :icon="['fas', 'circle']" :color="solePropPartner.dot.color" />
                                    <fa-icon v-if="solePropPartner.dotExtend" class="mt-1 ml-1" :icon="['fas', 'circle']" :color="solePropPartner.dotExtend.color" />
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                          </b-row>
                        </div>
                      </div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="calender-grid-container">
                      <div v-for="(eachMonth, index) in newList.slice(8, 12)" :key="index" class="grid-item">
                        <div class="month-bg-style">
                            {{ getMonthCapital(eachMonth.month) }}
                        </div>
                        <div class="triangle-bg"></div>
                        <div class="triangle-bg-two"></div>
                        <div class="calender-detail-wrapper">
                          <b-row class="no-gutters calender-content">
                            <div class="calender-tag">
                              ALL BUSINESSES
                            </div>
                            <div class="pl-2">
                              <b-row v-for="(business, index) in eachMonth.allBusiness" :key="index">
                                <b-col class="calender-body col-2">
                                  {{ business.date }}
                                </b-col>
                                <b-col>
                                  <div class="d-inline-block">
                                    <span v-html="business.name"></span>
                                    <fa-icon v-if="business.dot" class="mt-1 ml-1" :icon="['fas', 'circle']" :color="business.dot.color" />
                                    <fa-icon v-if="business.dotExtend" class="mt-1 ml-1" :icon="['fas', 'circle']" :color="business.dotExtend.color" />
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                          </b-row>
                          <b-row class="no-gutters calender-content">
                            <div class="calender-tag">
                              SDN BHD / LLP
                            </div>
                            <div class="pl-2">
                              <b-row v-for="(sdnbhdLlp, index) in eachMonth.sdnbhdLlp" :key="index">
                                <b-col class="calender-body col-2">
                                  {{ sdnbhdLlp.date }}
                                </b-col>
                                <b-col>
                                  <div class="d-inline-block">
                                    <span v-html="sdnbhdLlp.name"></span>
                                    <fa-icon v-if="sdnbhdLlp.dot" class="mt-1 ml-1" :icon="['fas', 'circle']" :color="sdnbhdLlp.dot.color" />
                                    <fa-icon v-if="sdnbhdLlp.dotExtend" class="mt-1 ml-1" :icon="['fas', 'circle']" :color="sdnbhdLlp.dotExtend.color" />
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                          </b-row>
                          <b-row v-if="eachMonth.solePropPartner.length" class="no-gutters calender-content">
                            <div class="calender-tag">
                              SOLE PROP / PARTNERSHIP / INDIVIDUALS
                            </div>
                            <div class="pl-2">
                              <b-row v-for="(solePropPartner, index) in eachMonth.solePropPartner" :key="index">
                                <b-col class="calender-body col-2">
                                  {{ solePropPartner.date }}
                                </b-col>
                                <b-col>
                                  <div class="d-inline-block">
                                    <span v-html="solePropPartner.name"></span>
                                    <fa-icon v-if="solePropPartner.dot" class="mt-1 ml-1" :icon="['fas', 'circle']" :color="solePropPartner.dot.color" />
                                    <fa-icon v-if="solePropPartner.dotExtend" class="mt-1 ml-1" :icon="['fas', 'circle']" :color="solePropPartner.dotExtend.color" />
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                          </b-row>
                        </div>
                      </div>
                    </div>
                  </swiper-slide>
                  <div class="swiper-button-prev d-none d-xl-block" slot="button-prev"></div>
                  <div class="swiper-button-next d-none d-xl-block" slot="button-next"></div>
                  <div class="swiper-pagination d-xl-none" slot="pagination"></div>
                  </swiper>

                <!-- Mobile Setting -->
                <swiper class="deadline-carousel d-lg-none" :options="swiperMobileOption" style="align-items: stretch;">
                  <swiper-slide v-for="(eachMonth, index) in newList" :key="index">
                    <div class="calender-grid-container">
                      <div class="grid-item">
                        <div class="month-bg-style">
                            {{ getMonthCapital(eachMonth.month) }}
                        </div>
                        <div class="triangle-bg"></div>
                        <div class="triangle-bg-two"></div>
                        <div class="calender-detail-wrapper">
                          <b-row class="no-gutters calender-content">
                            <div class="calender-tag">
                              ALL BUSINESSES
                            </div>
                            <div class="pl-2">
                              <b-row v-for="(business, index) in eachMonth.allBusiness" :key="index">
                                <b-col class="calender-body col-2">
                                  {{ business.date }}
                                </b-col>
                                <b-col>
                                  <div class="d-inline-block">
                                    <span v-html="business.name"></span>
                                    <fa-icon v-if="business.dot" class="mt-1 ml-1" :icon="['fas', 'circle']" :color="business.dot.color" />
                                    <fa-icon v-if="business.dotExtend" class="mt-1 ml-1" :icon="['fas', 'circle']" :color="business.dotExtend.color" />
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                          </b-row>
                          <b-row class="no-gutters calender-content">
                            <div class="calender-tag">
                              SDN BHD / LLP
                            </div>
                            <div class="pl-2">
                              <b-row v-for="(sdnbhdLlp, index) in eachMonth.sdnbhdLlp" :key="index">
                                <b-col class="calender-body col-2">
                                  {{ sdnbhdLlp.date }}
                                </b-col>
                                <b-col>
                                  <div class="d-inline-block">
                                    <span v-html="sdnbhdLlp.name"></span>
                                    <fa-icon v-if="sdnbhdLlp.dot" class="mt-1 ml-1" :icon="['fas', 'circle']" :color="sdnbhdLlp.dot.color" />
                                    <fa-icon v-if="sdnbhdLlp.dotExtend" class="mt-1 ml-1" :icon="['fas', 'circle']" :color="sdnbhdLlp.dotExtend.color" />
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                          </b-row>
                          <b-row v-if="eachMonth.solePropPartner.length" class="no-gutters calender-content">
                            <div class="calender-tag">
                              SOLE PROP / PARTNERSHIP / INDIVIDUALS
                            </div>
                            <div class="pl-2">
                              <b-row v-for="(solePropPartner, index) in eachMonth.solePropPartner" :key="index">
                                <b-col class="calender-body col-2">
                                  {{ solePropPartner.date }}
                                </b-col>
                                <b-col>
                                  <div class="d-inline-block">
                                    <span v-html="solePropPartner.name"></span>
                                    <fa-icon v-if="solePropPartner.dot" class="mt-1 ml-1" :icon="['fas', 'circle']" :color="solePropPartner.dot.color" />
                                    <fa-icon v-if="solePropPartner.dotExtend" class="mt-1 ml-1" :icon="['fas', 'circle']" :color="solePropPartner.dotExtend.color" />
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                          </b-row>
                        </div>
                      </div>
                    </div>
                  </swiper-slide>
                  <div class="swiper-pagination d-xl-none" slot="pagination"></div>
                  </swiper>
              </b-col>
              <b-col class="d-none align-items-end">
                <!-- <router-link :to="`/${$i18n.locale}/services?type=5`" class="item">Employer Registration<fa-icon class="icon" :icon="['fas', 'long-arrow-alt-right']" color="white"/></router-link> -->
                <router-link class="video-btn" :to="`/${$i18n.locale}?to=youtube-gallery`">Watch information videos<fa-icon id="anniversary-date-tooltip" class="ml-2" :icon="['fas', 'chevron-circle-right']" /></router-link>
              </b-col>
            </b-row>
          </div>
        </div>

        <!-- Remark -->
        <div class="container pt-5 pt-lg-4">
          <b-row class="px-3 px-lg-5 pt-5 pt-lg-0">
            <b-col class="col-lg-9">
              <div v-for="(item, index) in remarkListing[this.yearEndVal.name]" :key="index" class="d-flex">
                <p v-if="item.content" class="d-flex">
                  <fa-icon class="mr-3 mt-1" :icon="['fas', 'circle']" :color="item.color" />
                  {{ item.content }}
                </p>
              </div>
              <p v-if="anniversarydayVal.val" class="d-flex"><fa-icon class="mr-3 mt-1" :icon="['fas', 'circle']" color="purple" /> In a leap year, where there are 29 days in February, if your incorporation anniversary date falls within 31/01 - 28/02, the deadline will be 1 day earlier than the shown date.</p>
            </b-col>
          </b-row>
        </div>

        <!-- disclaimer -->
        <div class="container">
          <b-row class="px-3 px-lg-5">
            <b-col class="disclaimer col-lg-9">
              <hr>
              <p>The content is provided for general informational purposes and non-commercial use only. It is not exhaustive and may change over time. Furthermore, you are not automatically subject to all deadlines shown, as some are conditional. Please consult your qualified advisor for guidance. We do not accept responsibility for any errors or inaccuracies on the website.</p>
              <!-- <p class="mt-0">For non-commercial use only. All rights reserved.</p> -->
            </b-col>
          </b-row>
        </div>
    </container-style>

    <!-- remove later -->
    <container-style class="d-none" direction="style-right" bgColor="bg-white" :bgPath="null">
      <div class="container">
        <div>
          <h1>DEAD Line</h1>
          <header-tile content="DEADLINE"></header-tile>
          <div class="shadow-lg"
            style="position: relative; border-radius: 15px; overflow: hidden; max-width: 320px; padding: 20px;">
            <div style="line-height: 1;position: absolute;right: 18px;top: -17px;font-weight: 900;font-size: 80px;opacity: 0.15;/* rotate: -90deg; *//* transform: skewY(15deg); */letter-spacing: -5px;">
              JAN
            </div>
            <div class="triangle-bg-one" style="
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.02);
                clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
                position: absolute;
                bottom: 0;
                right: 0;
            "></div>
            <div style="padding-top: 50px; position: relative; font-size: 14px;">
              <b-row class="no-gutters mb-3">
                <div style="padding: 4px 16px;background-color: bisque;border-radius: 30px;display: inline-block;font-weight: 500; margin-bottom: 8px;">
                  ALL BUSINESSES
                </div>
                <div style="padding-left: 14px;">
                  <b-row>
                    <b-col class="col-2" style="color: goldenrod; font-weight: 700;">15/01</b-col>
                    <b-col>EPF/ SOCSO / EIS / PCB / HRDC</b-col>
                  </b-row>
                  <b-row>
                    <b-col class="col-2" style="color: goldenrod; font-weight: 700;">31/01</b-col>
                    <b-col>SST</b-col>
                  </b-row>
                </div>
              </b-row>
              <b-row class="no-gutters mb-3">
                <div style="padding: 4px 16px;background-color: bisque;border-radius: 30px;display: inline-block;font-weight: 500; margin-bottom: 8px;">
                  SDN BHD / LLP
                </div>
                <div style="padding-left: 14px;">
                  <b-row>
                    <b-col class="col-2" style="color: goldenrod; font-weight: 700;">15/01</b-col>
                    <b-col>CP204 Tax Instalment</b-col>
                  </b-row>
                </div>
              </b-row>
              <b-row class="no-gutters mb-3">
                <div style="padding: 4px 16px;background-color: bisque;border-radius: 30px;display: inline-block;font-weight: 500; margin-bottom: 8px;">
                  SOLE PROP / PARTNERSHIP / INDIVIDUALS
                </div>
                <div style="padding-left: 14px;">
                  <b-row>
                    <b-col class="col-2" style="color: goldenrod; font-weight: 700;">15/01</b-col>
                    <b-col>Form BE<fa-icon style="margin-left: 8px;" :icon="['fas', 'circle']" color="green"/></b-col>
                  </b-row>
                  <b-row>
                    <b-col class="col-2" style="color: goldenrod; font-weight: 700;">30/01</b-col>
                    <b-col>CP500 Tax Instalment</b-col>
                  </b-row>
                </div>
              </b-row>
            </div>
          </div>
          <b-row>
            <b-col>
              <div class="custom-checkbox-wrapper">
                <label class="custom-checkbox">
                  <input type="checkbox" v-model="isChecked" @change="handleChange" />
                  <span class="checkmark"></span>
                  <span class="label-text">please check</span>
                </label>
              </div>
            </b-col>

            <b-col>
              <div class="custom-dropdown">

                {{ anniversarydayVal }}
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <p>isChecked : {{ isChecked }}</p>
              <p>selected <b>Year END</b> :{{ yearEndVal }}</p>
              <p>selected <b>Anniversary Month</b> :{{ anniversaryMonthVal }}</p>
              <p>selected <b>Anniversary Day</b> :{{ anniversarydayVal }}</p>
              <p>Final Date after adding 30 days: {{ finalMonthAndDate }}</p> <!-- Display final date -->
              <p><strong>datelineDataListing</strong>: {{ datelineDataListing[yearEndVal.name] }}</p>
              <p>pickedData: {{ pickedData }}</p>
              <p>newList : {{ newList }}</p>
              <!-- <p><strong>getYearEndData</strong> {{ getYearEndData }}</p> -->
              <!-- <p>getselectedMonthData: {{ getselectedMonthData }}</p> -->
              <hr>
              <!-- {{ yearEndItem[yearEndVal.name] }} -->
              <!-- <div v-for="(eachMonth, index) in datelineDataListing[yearEndVal.name]" :key="index"> -->
              <div v-for="(eachMonth, index) in newList" :key="index">
                <div class="mb-5 bg-gold">
                  <h3>{{ eachMonth.month }}</h3>
                  <div>
                    <b class="mb-2 bg-warning">ALL BUSINESSES</b>
                    <div v-for="(business, index) in eachMonth.allBusiness" :key="index">
                      <div class="d-flex"><strong class="mr-4">{{ business.date }}</strong> <div class="d-flex" v-html="business.name"></div><fa-icon
                          v-if="business.dot" :icon="['fas', 'circle']" :color="business.dot.color" /></div>
                    </div>
                    <hr>
                  </div>
                  <div>
                    <b class="mb-2 bg-warning">SDN BHD / LLP</b>
                    <div v-for="(sdnbhdLlp, index) in eachMonth.sdnbhdLlp" :key="index">
                      <div class="d-flex"><strong class="mr-4">{{ sdnbhdLlp.date }}</strong> {{ sdnbhdLlp.name
                        }}<fa-icon v-if="sdnbhdLlp.dot" class="mt-1 ml-1" :icon="['fas', 'circle']" :color="sdnbhdLlp.dot.color" /></div>
                    </div>
                    <hr>
                  </div>
                  <div v-if="eachMonth.solePropPartner.length">
                    <b class="mb-2 bg-warning">SOLE PROP / PARTNERSHIP / INDIVIDUALS</b>
                    <div v-for="(solePropPartner, index) in eachMonth.solePropPartner" :key="index">
                      <div class="d-flex"><strong class="mr-4">{{ solePropPartner.date }}</strong> {{
                        solePropPartner.name
                        }}<fa-icon v-if="solePropPartner.dot" :icon="['fas', 'circle']"
                          :color="solePropPartner.dot.color" />
                      </div>
                    </div>
                    <hr>
                  </div>
                </div>
              </div>

            </b-col>
          </b-row>
        </div>
      </div>
    </container-style>
  </div>
</template>

<script>
import heroBanner from '@/components/heroBanner.vue'
import containerStyle from '../components/containerStyle.vue'
import headerTile from '../components/headerTile.vue'

export default {
  name: 'deadline',
  metaInfo () {
    const title = 'Tyng & Co | Deadlines'
    const description = 'Where Compliance Meets Peace of Mind'
    document.querySelector('meta[name="description"]').content = description

    return {
      title,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: description
        },
        {
          vmid: 'keywords',
          name: 'keywords',
          content: 'tax deadline, SSM filing deadline, employer registration deadline, company compliance calendar, business deadlines Malaysia'
        },
        // Open Graph tags for better social sharing
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'Business Tax & Compliance Deadlines Calculator | Tyng & Co'
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: 'Interactive business compliance calendar. Calculate your tax submission, SSM filing, and employer registration deadlines based on your company\u2019s financial year end and incorporation date.'
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'website'
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: 'https://tyng-ca.com.my/deadline'
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: `https://tyng-ca.com.my/${this.$i18n.locale}/deadline`
        }
      ]
    }
  },
  components: {
    'hero-banner': heroBanner,
    'container-style': containerStyle,
    'header-tile': headerTile
  },
  data () {
    return {
      isDesktop: window.innerWidth >= 992,
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 50,
        // watchOverflow: true,
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          320: {
            slidesPerView: 1
          },
          640: {
            slidesPerView: 1
          },
          1024: {
            slidesPerView: 1
          }
        }
      },
      swiperMobileOption: {
        slidesPerView: 1,
        spaceBetween: 20,
        autoHeight: true,
        // watchOverflow: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + '</span>'
          }
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          320: {
            slidesPerView: 1
          },
          640: {
            slidesPerView: 1
          },
          1024: {
            slidesPerView: 1
          }
        }
      },
      datelineDataListing: [],
      yearEndListing: [],
      remarkListing: [],
      newListing: [],
      yearEndVal: { val: '12', name: 'December' },
      anniversaryMonthVal: { val: null, name: '' },
      anniversarydayVal: { val: null, name: '' },
      pickedData: { date: '22/02', name: 'SSM Annual Return (Sdn Bhd)', dot: { color: 'purple' } },
      finaldate: {
        month: '',
        day: ''
      },
      months: [
        { val: '1', name: 'January' },
        { val: '2', name: 'February' },
        { val: '3', name: 'March' },
        { val: '4', name: 'April' },
        { val: '5', name: 'May' },
        { val: '6', name: 'June' },
        { val: '7', name: 'July' },
        { val: '8', name: 'August' },
        { val: '9', name: 'September' },
        { val: '10', name: 'October' },
        { val: '11', name: 'November' },
        { val: '12', name: 'December' }
      ],
      days: Array.from({ length: 31 }, (_, i) => ({
        val: (i + 1).toString(),
        name: (i + 1).toString()
      })),
      isChecked: {
        type: Boolean,
        default: true
      }
    }
  },
  methods: {
    async fetchData () {
      const res = await fetch('../data-lists/deadline-calendar.json')
      const val = await res.json()
      this.datelineDataListing = val
      this.fetchRemarkData()
    },
    async fetchRemarkData () {
      const res = await fetch('../data-lists/deadline-remark.json')
      const val = await res.json()
      this.remarkListing = val
      // if (val[this.yearEndVal.name]) {
      //   val[this.yearEndVal.name].forEach(item => {
      //     console.log(`Color: ${item.color}, Content: ${item.content}`)
      //   })
      // }
    },
    checkDevice () {
      this.isDesktop = window.innerWidth >= 992
      console.log('this.isDesktop == ', this.isDesktop)
    },
    onYearEndChange (value) {
      this.anniversaryMonthVal = { val: null, name: 'Month' }
      this.anniversarydayVal = { val: null, name: 'Day' }
      this.isChecked = true
      // if (value.indexOf('Reset me!') !== -1) this.value = []
    },
    onMonthChange () {
      // Reset the selected day when the month changes
      this.isChecked = false
      this.anniversarydayVal = { val: null, name: 'Day' }
    },
    onDayChange () {
      // const _this = this
      // console.log('day change: ', _this.finaldate.day)
    },
    getDaysInMonth (month) {
      const year = new Date().getFullYear() // Use the current year
      return new Date(year, parseInt(month), 0).getDate()
    },
    convertDateStringToNumeric (dateString) {
      const [day, monthName] = dateString.split(' ')

      const month = this.months.find(m => m.name === monthName)

      console.log('on month change dateString = ', dateString)
      console.log('on month change this.months', this.months)

      if (month) {
        return `${day.padStart(2, '0')}/${month.val.padStart(2, '0')}`
      } else {
        console.error(`Month "${monthName}" not found in the months array.`)
        return null
      }
    },
    handleChange () {
      this.$emit('input', this.isChecked)
    },
    getMonthCapital (month) {
      const monthMapping = {
        January: 'Jan',
        February: 'Feb',
        March: 'Mar',
        April: 'Apr',
        May: 'May',
        June: 'Jun',
        July: 'Jul',
        August: 'Aug',
        September: 'Sep',
        October: 'Oct',
        November: 'Nov',
        December: 'Dec'
      }
      // Return the abbreviation or the original value if not found
      return monthMapping[month] || month
    }
  },
  watch: {
    isChecked: {
      handler (newValue, oldValue) {
        console.log('Checkbox value changed:', {
          new: newValue,
          old: oldValue
        })
        if (newValue) {
          this.anniversaryMonthVal.val = null
          this.anniversarydayVal.val = null
        }
      },
      immediate: true // This will trigger when component is created
    }
  },
  computed: {
    computedBackgroundStyle () {
      if (this.isDesktop) {
        return {
          'background-image': `url(${require('@/assets/images/deadline/deadlines.png')})`
        }
      } else {
        return {
          'background-image': 'none'
        }
      }
    },
    newList () {
      // Create a deep copy of the original data
      const selectedYearEndList = JSON.parse(JSON.stringify(this.datelineDataListing[this.yearEndVal.name] || []))
      // console.log('this.anniversarydayVal.val ==', selectedYearEndList)
      // console.log('pass = ', this.finaldate.month.name)
      let foundMonthIndex = -1
      if (selectedYearEndList.length && this.finalMonthAndDate.month) {
        foundMonthIndex = selectedYearEndList.findIndex(item => item.month === this.finalMonthAndDate.month)
      } else {
        console.log('Error! Unable to get foundMonthIndex')
      }

      if (foundMonthIndex !== -1) {
        selectedYearEndList[foundMonthIndex].sdnbhdLlp.push(this.pickedData)
        selectedYearEndList[foundMonthIndex].sdnbhdLlp.sort((a, b) => {
          console.log()
          const [dayA] = a.date.split('/')
          const [dayB] = b.date.split('/')
          return parseInt(dayA) - parseInt(dayB)
        })
      } else {
        console.log('Error! Unable to foundMonthIndex')
      }
      return selectedYearEndList
    },
    filteredDays () {
      if (!this.anniversaryMonthVal || !this.anniversaryMonthVal.val) {
        // console.log('Error, getting empty anniversaryMonthVal or anniversaryMonthVal, will return this.days')
        // console.log('====================================================================')
        // console.log('anniversaryMonthVal == ', this.anniversaryMonthVal)
        // console.log('this.days === ', this.days)
        // console.log('====================================================================')
        return this.days
      }
      const maxDays = this.getDaysInMonth(this.anniversaryMonthVal.val)

      const monthOfDays = Array.from({ length: maxDays }, (_, i) => ({
        val: (i + 1).toString(),
        name: (i + 1).toString()
      }))

      if (this.anniversaryMonthVal.name === 'February') {
        const newObj = { val: '28', name: '29' }
        monthOfDays.push(newObj)
      } else {
        console.log('is not FEB')
      }

      console.log('maxDays ==== ', monthOfDays)

      return monthOfDays
    },
    finalMonthAndDate () {
      const _this = this
      if (this.anniversaryMonthVal.val && this.anniversarydayVal.val) {
        let selectedDate = new Date(2025, this.anniversaryMonthVal.val - 1, this.anniversarydayVal.val)
        selectedDate.setDate(selectedDate.getDate() + 29)

        // Handle year rollover
        if (selectedDate.getFullYear() > 2025) {
          // If date goes into next year, reset to January
          selectedDate = new Date(2025, 0, selectedDate.getDate())
        }

        _this.finaldate.month = this.months[selectedDate.getMonth()]
        _this.finaldate.day = selectedDate.getDate()
        const toConvertDate = `${_this.finaldate.day} ${_this.finaldate.month.name}`
        _this.pickedData.date = _this.convertDateStringToNumeric(toConvertDate)

        return { day: _this.finaldate.day, month: _this.finaldate.month.name }
      } else {
        console.log('Error! Unable to get finalMonthAndDate ()')
      }
      return { day: null, month: null }
    },
    isDaySelectDisabled () {
      return !this.anniversaryMonthVal || !this.anniversaryMonthVal.val
    }
  },
  mounted () {
    this.fetchData(this.$i18n.locale)
    this.$nextTick(() => {
      this.checkDevice()
      window.addEventListener('resize', this.checkDevice)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.checkDevice)
  }
}

</script>

<style lang="scss" scoped>

</style>
