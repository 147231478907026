// YoutubeSlider.vue
<template>
  <div class="youtube-slider">
    <swiper
      :options="swiperOptions"
    >
      <swiper-slide v-for="(video, index) in videos" :key="index">
        <div class="video-container">
          <iframe
            :id="'youtube-player-' + index"
            :src="`https://www.youtube.com/embed/${video.id}?enablejsapi=1&rel=0&showinfo=0&modestbranding=1`"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            class="youtube-iframe"
          ></iframe>
          <h3 class="video-title d-none">{{ video.title }}</h3>
        </div>
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'YoutubeSlider',
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      videos: [
        {
          id: 'FFD8A1WZVJM',
          title: 'e-PCB Plus - Submission & Payment'
        },
        {
          id: 'cWrPqvL3CWY',
          title: 'EPF - How To Submit and Pay Monthly Contribution'
        },
        {
          id: '_Rfc1lsaE6M',
          title: 'IRB - How To Pay Monthly Tax Deduction (PCB)'
        },
        {
          id: 'ZSHHVwSo_Iw',
          title: 'SOCSO - How to Pay Monthly Contribution'
        },
        {
          id: 'jpuU2DbVLvg',
          title: 'EPF - How To Pay Monthly Contribution'
        },
        {
          id: 'UTrQs1adibk',
          title: 'SOCSO - How to Submit Monthly Contribution'
        },
        {
          id: 'T9rlVMD525g',
          title: 'HRD Corp - How To Make Payment Of Levy'
        }
      ],
      swiperOptions: {
        slidesPerView: 1.5,
        centeredSlides: true,
        spaceBetween: 30,
        loop: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        on: {
          slideChange: () => {
            this.stopAllVideos()
          },
          init: () => {
            this.loadYouTubeAPI()
          }
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          768: {
            slidesPerView: 1.3,
            spaceBetween: 20
          },
          1024: {
            slidesPerView: 1.5,
            spaceBetween: 30
          }
        }
      },
      players: {}
    }
  },
  mounted () {
    // Load YouTube API only after component is mounted
    this.$nextTick(() => {
      this.loadYouTubeAPI()
    })
  },
  methods: {
    loadYouTubeAPI () {
      if (!window.YT) {
        const tag = document.createElement('script')
        tag.src = 'https://www.youtube.com/iframe_api'
        const firstScriptTag = document.getElementsByTagName('script')[0]
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

        window.onYouTubeIframeAPIReady = () => {
          this.initializePlayers()
        }
      } else if (window.YT && window.YT.Player) {
        this.initializePlayers()
      }
    },

    initializePlayers () {
      this.$nextTick(() => {
        this.videos.forEach((_, index) => {
          const playerId = `youtube-player-${index}`
          const playerElement = document.getElementById(playerId)

          if (playerElement && !this.players[playerId]) {
            try {
              this.players[playerId] = new window.YT.Player(playerId, {
                events: {
                  onReady: (event) => {
                    console.log(`Player ${index} ready`)
                  },
                  onStateChange: (event) => {
                    if (event.data === window.YT.PlayerState.PLAYING) {
                      this.stopOtherVideos(playerId)
                    }
                  }
                }
              })
            } catch (error) {
              console.error(`Error initializing player ${playerId}:`, error)
            }
          }
        })
      })
    },

    stopOtherVideos (currentPlayerId) {
      Object.entries(this.players).forEach(([id, player]) => {
        if (id !== currentPlayerId && player && typeof player.stopVideo === 'function') {
          try {
            player.stopVideo()
          } catch (error) {
            console.error(`Error stopping video ${id}:`, error)
          }
        }
      })
    },

    stopAllVideos () {
      Object.values(this.players).forEach(player => {
        if (player && typeof player.stopVideo === 'function') {
          try {
            player.stopVideo()
          } catch (error) {
            console.error('Error stopping video:', error)
          }
        }
      })
    }
  },
  beforeDestroy () {
    Object.values(this.players).forEach(player => {
      if (player && typeof player.destroy === 'function') {
        try {
          player.destroy()
        } catch (error) {
          console.error('Error destroying player:', error)
        }
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.youtube-slider {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  /* padding: 20px; */
  position: relative;

  .video-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    background: #000;
    border-radius: 30px;
    overflow: hidden;
  }

  .youtube-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .video-title {
    position: absolute;
    bottom: -30px;
    left: 0;
    width: 100%;
    text-align: center;
    color: #333;
    font-size: 1.2rem;
    margin: 10px 0;
  }

  .swiper-slide {
    opacity: 0.4;
    transition: all 0.3s ease;
    pointer-events: none;
    @include media-breakpoint-up(lg) {
      transform: scale(0.85);
    }
  }

  .swiper-slide-active {
    opacity: 1;
    transform: scale(0.9);
    pointer-events: fill;
    @include media-breakpoint-up(lg) {
      transform: scale(1);
    }
  }

  /* Navigation buttons */
  .swiper-button-prev,
  .swiper-button-next {
    width: 44px;
    height: 44px;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 50%;
    color: goldenrod;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    // font-size: 20px;
    font-size:  24px;
    font-weight: bold;
    @include media-breakpoint-up(lg) {
      font-size:  var(--swiper-navigation-size);
    }
  }

  .swiper-button-prev {
    left: -12px;
    @include media-breakpoint-up(lg) {
      left: 10px;
    }
  }
  .swiper-button-next {
    right: -12px;
    @include media-breakpoint-up(lg) {
      right: 10px;
    }
  }

  @media (max-width: 768px) {
    .youtube-slider {
      padding: 10px;
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 36px;
      height: 36px;
    }
  }

}
</style>
