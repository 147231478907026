<template>
  <div id="app">
    <div id="nav">
      <top-header></top-header>
      <whats-app></whats-app>
      <!-- <router-link to="/">Home</router-link> | -->
      <!-- <router-link to="/about">About</router-link> -->
    </div>
    <router-view/>
    <bottom-footer></bottom-footer>
  </div>
</template>

<script>
import topHeader from '@/components/topHeader.vue'
import bottomFooter from '@/components/bottomFooter.vue'
import whatsApp from '@/components/whatsApp.vue'

export default {
  metaInfo () {
    return {
      titleTemplate: '',
      meta: [
        { charset: 'utf-8' },
        {
          vmid: 'viewport',
          name: 'viewport',
          content: 'width=device-width, initial-scale=1'
        }
        // Remove the default description here since we'll override it in components
      ]
    }
  },
  components: {
    'top-header': topHeader,
    'bottom-footer': bottomFooter,
    'whats-app': whatsApp
  }
}
</script>

<style lang="scss">
@import './assets/scss/app.scss';
@import './assets/scss/base.scss';
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
}

</style>
