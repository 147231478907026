<template>
  <div class="why-us ">
    <hero-banner
      :bgPath="`assets/images/hero-banner/arrow.png`"
      :bgPathMobile="`assets/images/hero-banner/arrow-mobile.png`"
      height="600"
      horizontal="right"
      dVertical="d-center"
      mVertical="m-top"
      color=""
      shadow="true"
      contentCol="6"
      textAlign="text-center text-md-left"
      :title="$t('why-us.banner.title')"
      cta="">
    </hero-banner>
    <b-container class="py-5 my-lg-5">
      <b-row>
        <b-col>
          <div class="px-3 px-lg-5 mx-lg-5">
            <h3 class="mb-4">{{ $t('why-us.intro.title1') }}</h3>
            <p v-html="$t('why-us.intro.desc1')" />
            <p v-html="$t('why-us.intro.highlight')" />
            <p v-html="$t('why-us.intro.desc2')" />
            <h3 class="mt-5 mb-4">{{ $t('why-us.intro.title2') }}</h3>
            <p v-html="$t('why-us.intro.desc3')" />
          </div>
        </b-col>
      </b-row>
    </b-container>
    <div class="usp-container pb-5 mb-lg-5">
      <b-container>
        <b-row>
          <b-col v-for="usp in $t('why-us.usp')" :key="usp.name" cols="12" sm="6" md="6" lg="4" class="my-2">
            <div class="usp">
              <div class="icon"><img :src="require('@/'+usp.icon)" alt=""></div>
              <div class="title"><h5>{{ usp.name }}</h5></div>
              <div class="desc">
                <div class="align-self-center">
                  <h5 class="mb-4">{{ usp.name }}</h5>
                  <p>{{ usp.desc }}</p>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
    </b-container>
    </div>
  </div>
</template>

<script>
import heroBanner from '@/components/heroBanner.vue'

export default {
  name: 'why-us',
  components: {
    'hero-banner': heroBanner
  }
}
</script>

<style lang="scss" scoped>

</style>
