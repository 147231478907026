<template>
  <div class="expertise-slider-wrapper">
    <div class="expertise-slider-container">
      <!-- Custom Tab Navigation -->
      <div class="tab-navigation d-none d-lg-flex">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        class="tab-item"
        :class="{ 'active': activeIndex === index }"
        @click="slideTo(index)"
      >
        <span v-html="tab" class="d-inline-flex"></span>
        <!-- {{ tab }} -->
        <div class="tab-underline" v-if="activeIndex === index"></div>
      </div>
    </div>

      <!-- Swiper Container -->
      <swiper class="swiper" ref="mySwiper" :options="swiperOptions">

          <swiper-slide>
            <div class="slide-content">
              <div class="d-flex flex-column flex-lg-row h-100">
                <div class="tab-title d-lg-none">01 - Entrepreneurship</div>
                <div class="h-100 align-content-lg-center">
                  Building a company from the ground up means spending all your time on funding, development, marketing, cash flow, and building your workforce.
<br><br>
<strong>But don’t forget about compliance.</strong> Doing so could leave you with missed deadlines, intrusive audits, and expensive fines.
<br><br>
To avoid this, you need a secretarial & tax firm, so you look for the cheapest possible, right?
                </div>
              </div>
            </div>
          </swiper-slide>

          <swiper-slide>
            <div class="slide-content">
              <div class="d-flex flex-column flex-lg-row h-100">
                <div class="tab-title d-lg-none">02 - Compliance</div>
                <div class="h-100 align-content-lg-center"><strong>We get it.</strong> Saving money while running a startup is essential. While trying to save money on compliance can be tempting, cutting costs with low-cost options will get you the bare minimum, leaving your startup vulnerable to higher costs when things inevitably go wrong.</div>
              </div>
            </div>
          </swiper-slide>

          <swiper-slide>
            <div class="slide-content">
              <div class="d-flex flex-column flex-lg-row h-100">
                <div class="tab-title d-lg-none">03 - Solution</div>
                <div class="h-100 align-content-lg-center">
                  <p><strong>The solution is to use an all-rounded advisor like Tyng & Co from the beginning.</strong></p>

With comprehensive secretarial and tax services that provide valuable insights into your financials & operations on day one, you’ll have peace of mind for your business.
                </div>
              </div>
            </div>
          </swiper-slide>

          <div class="swiper-button-prev d-none d-xl-block" slot="button-prev"></div>
          <div class="swiper-button-next d-none d-xl-block" slot="button-next"></div>
          <div class="swiper-pagination d-xl-none" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>

import { Swiper } from 'vue-awesome-swiper'

export default {
  name: 'SwiperSlider',
  components: {
    Swiper
  },
  data () {
    return {
      activeIndex: 0,
      tabs: [
        '01<br class="d-lg-none"><span class="d-none d-lg-block">&nbsp;-&nbsp;</span>Entrepreneurship',
        '02<br class="d-lg-none"><span class="d-none d-lg-block">&nbsp;-&nbsp;</span>Compliance',
        '03<br class="d-lg-none"><span class="d-none d-lg-block">&nbsp;-&nbsp;</span>Solution'
      ],
      swiperOptions: {
        direction: 'horizontal',
        loop: false,
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        slidesPerView: 1,
        spaceBetween: 30,
        on: {
          slideChange: () => {
            this.activeIndex = this.$refs.mySwiper.$swiper.activeIndex
          }
        }
      }
    }
  },
  methods: {
    slideTo (index) {
      this.$refs.mySwiper.$swiper.slideTo(index)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
