<template>
    <div>
        <header :on="handleScroll" class="rounded-bottom--right-2" :class="scrolled ? 'headroom--unpinned': 'headroom--pinned' ">
            <b-navbar toggleable="lg" type="dark" class="container py-3 py-lg-4 px-0 justify-content-end justify-content-md-between">
                <b-navbar-brand :to="`/${$i18n.locale}`" class="logo col-9 col-md-4 mr-auto">
                    <b-img class="d-block" src="../assets/logo_text.png"></b-img>
                </b-navbar-brand>

                <!-- <div class="btn-language d-md-none">
                    <div>
                      <b-button @click.prevent="setLocale('en')" v-if="`${$i18n.locale}` == 'cn'"><b>English</b></b-button>
                      <b-button @click.prevent="setLocale('cn')"  v-if="`${$i18n.locale}` == 'en'"><b>中文</b></b-button>
                    </div>
                </div> -->

                <b-navbar-toggle target="nav-collapse" class="burger-menu" @click="toggleBodyScroll">
                  <div class="burger-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </b-navbar-toggle>

                <b-collapse id="nav-collapse" class="px-3" is-nav>
                <div class="navbar-bg-degsign-one"></div>
                <div class="navbar-bg-degsign-two"></div>
                <div class="navbar-bg-degsign-three"></div>
                <b-navbar-nav class="ml-auto my-3 my-md-0">
                    <b-nav-item ref="home" @click="onMenuNaviClick" :to="`/${$i18n.locale}`"><div class="nav-cta">{{ $t('nav.home') }}</div></b-nav-item>
                    <b-nav-item ref="services" @click="onMenuNaviClick" :to="`/${$i18n.locale}/services`"><div class="nav-cta">{{ $t('nav.services') }}</div></b-nav-item>
                    <b-nav-item @click="onMenuNaviClick" :to="`/${$i18n.locale}/deadline`"><div class="nav-cta">{{ $t('nav.deadline') }}</div></b-nav-item>
                    <b-nav-item @click="onMenuNaviClick" :to="`/${$i18n.locale}/management-team`"><div class="nav-cta">{{ $t('nav.management-team') }}</div></b-nav-item>
                    <b-nav-item ref="contactUs" @click="onMenuNaviClick" :to="`/${$i18n.locale}/contact-us`" class="nav-contact-us"><div class="nav-cta">{{ $t('nav.contact-us') }}</div></b-nav-item>
                </b-navbar-nav>

                <!-- Right aligned nav items -->
                <b-navbar-nav class="py-3 px-2 p-lg-0 d-none d-md-block">
                    <!-- <b-nav-form>
                        <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
                        <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
                    </b-nav-form> -->

                    <!-- <div class="btn-language">
                        <b-button @click.prevent="setLocale('en')" v-if="`${$i18n.locale}` == 'cn'"><b>English</b></b-button>
                        <b-button @click.prevent="setLocale('cn')"  v-if="`${$i18n.locale}` == 'en'"><b>中文</b></b-button>
                    </div> -->

                    <!-- <b-nav-item-dropdown text="Lang" right>
                        <b-dropdown-item
                            @click.prevent="setLocale('en')"
                        >EN</b-dropdown-item>
                        <b-dropdown-item
                            @click.prevent="setLocale('cn')"
                        >中文</b-dropdown-item>
                    </b-nav-item-dropdown> -->
                </b-navbar-nav>
                </b-collapse>
            </b-navbar>
        </header>
    </div>
</template>

<script>
export default {
  name: 'top-header',
  data () {
    return {
      langs: ['cn', 'en'],
      limitPosition: 50,
      scrolled: false,
      lastPosition: 0,
      isScrollLocked: false,
      pagaName: ''
    }
  },
  methods: {
    toggleBodyScroll () {
      this.isScrollLocked = !this.isScrollLocked
      document.body.style.overflow = this.isScrollLocked ? 'hidden' : ''
    },
    onMenuNaviClick () {
      this.isScrollLocked = false
      document.body.style.overflow = ''
    },
    setLocale (locale) {
      this.$i18n.locale = locale
      this.$router.push({
        params: { lang: locale }
      })
    },
    handleScroll () {
      if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
        this.scrolled = true
        // move up!
      }
      if (window.scrollY < 50) {
        this.scrolled = false
        // move down
      }

      this.lastPosition = window.scrollY
      // this.scrolled = window.scrollY > 250;
    }
  },
  watch: {
    $route: {
      handler (to) {
        const hasQueryParams = Object.keys(to.query).length > 0
        const parts = to.path.split('/')
        this.pagaName = parts[parts.length - 1]
        if (this.pagaName === 'en' || this.pagaName === 'cn') {
          this.pagaName = 'home'
        } else {
          const navLink = this.$refs.home.querySelector('.nav-link')
          navLink.classList.remove('router-link-exact-active')
        }

        this.$nextTick(() => {
          if (this.pagaName === 'home' && hasQueryParams && this.$refs.home) {
            console.log('add home')
            const navLink = this.$refs.home.querySelector('.nav-link')
            navLink.classList.add('router-link-exact-active')
          }
          if (this.pagaName === 'services' && hasQueryParams && this.$refs.services) {
            const navLink = this.$refs.services.querySelector('.nav-link')
            navLink.classList.add('router-link-exact-active')
          }
          if (this.pagaName === 'contact-us' && hasQueryParams && this.$refs.contactUs) {
            const navLink = this.$refs.contactUs.querySelector('.nav-link')
            navLink.classList.add('router-link-exact-active')
          }
        })
      },
      immediate: true
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style lang="scss" scoped>
// @import '../assets/scss/top-header.scss';
// #nav a.router-link-exact-active{
//     color: white;
// }
</style>
