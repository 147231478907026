<template>
    <div class="footer-wrapper">
      <svg xmlns:xlink="http://www.w3.org/1999/xlink" class="d-none d-lg-block" viewBox="0 0 1920 173"><path d="M80,77.117,1840,0c44.183,0,80,34.527,80,77.117V173H0V154.234C0,111.644,35.817,77.117,80,77.117Z" fill="currentColor"></path></svg>
      <svg xmlns:xlink="http://www.w3.org/1999/xlink" class="d-lg-none" viewBox="0 0 375 79.998"><path d="M-3856.818,20177h-375a42.686,42.686,0,0,1,12.846-26.977,38.873,38.873,0,0,1,26.988-10.994L-3896.84,20097a38.126,38.126,0,0,1,15.58,3.3,39.827,39.827,0,0,1,12.722,9.006,42.068,42.068,0,0,1,8.577,13.359,43.582,43.582,0,0,1,3.145,16.359V20177Z" transform="translate(4231.816 -20097)" fill="currentColor"></path></svg>
      <footer class="pb-4" style="margin-top: -1px;">
          <b-container>
            <b-row>
              <div class="footer-slogan">
                <h2 class="caption flex-column text-center" :class="ctaPath === 'management-team' ? 'd-flex d-lg-none' : 'd-none'">
                    <div><span v-html="footerContent.fixedText"></span></div>
                    <div><span class="gradient-text">{{ footerContent.dynamicText }}</span><span class="end-text" v-html="footerContent.endText"></span></div>
                </h2>
                <h2 class="caption" :class="[footerContent.positioning, { 'd-none d-lg-flex': ctaPath == 'management-team' } ]">
                    <span class="first-text gradient-text">{{ footerContent.dynamicText }}</span>
                    <span class="mid-text" v-html="footerContent.fixedText"></span>
                    <span class="end-text" v-html="footerContent.endText"></span>
                </h2>
                <!-- <span class="gradient-text pr-1" :class="`float-${direction}`">{{ dynamicText }}</span><span v-html="fixedText"></span><span v-html="endText"></span> -->
              </div>
            </b-row>
            <b-row class="text-white justify-content-center mt-4 mt-lg-2">
              <b-col>
                <p class="text-center description" v-html="footerContent.description"></p>
              </b-col>
            </b-row>
            <b-row class="justify-content-center">
              <b-btn v-if="footerContent.ctaPath === 'self'" class="btn-gold mx-auto mt-4 mb-4" @click="scrollToForm">
                  <b>{{ footerContent.ctaText }}</b>
              </b-btn>
              <b-btn v-else :to="`/${$i18n.locale}/${footerContent.ctaPath}`" class="btn-gold mx-auto mt-4 mb-4">
                  <b>{{ footerContent.ctaText }}</b>
              </b-btn>
            </b-row>
            <b-row>
                <b-col class="content d-flex flex-column flex-lg-row mt-2 mt-lg-4 justify-content-center">
                    <div class="copy-right text-center text-lg-left mr-3">Copyright © 2020 TYNG & CO (NF 2148)<br class="d-linline-block d-lg-none"><div class="d-none d-lg-inline-block">|</div><div class="mt-1 mt-lg-0 d-inline-block mx-0">An accounting firm in Kuala Lumpur. All rights reserved.</div></div>
                    <div class="footer-links mx-auto mx-lg-0 mt-4 mt-lg-0">
                        <div class="d-none d-lg-inline-block">|</div>
                        <div class="ml-0 ml-lg-4 d-inline-block"><a :href="`/${$i18n.locale}/policy`">Privacy Statement</a></div>
                        <div class="d-inline-block">|</div>
                        <div class="d-inline-block"><a :href="`/${$i18n.locale}/legal`">Legal Disclaimer</a></div>
                        <!-- <div class="d-inline-block">Disclaimer</div> -->
                    </div>
                </b-col>
            </b-row>
          </b-container>
      </footer>
    </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      langs: ['cn', 'en'],
      dynamicText: 'Together',
      fixedText: ', We Thrive',
      endText: '',
      description: 'Tyng & Co is your end-to-end partner for compliance — and beyond.',
      pagaName: 'home',
      positioning: 'left',
      ctaText: 'Contact Tyng & Co',
      ctaPath: 'contact-us'
    }
  },
  methods: {
    setLocale (locale) {
      console.log('page change')
      this.$i18n.locale = locale
      this.$router.push({
        params: { lang: locale }
      })
    },
    scrollToForm () {
      const element = document.getElementById('form-container')
      if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset
        window.scrollTo({
          top: elementPosition - 100, // Subtract 50px for the offset
          behavior: 'smooth'
        })
      }
    }
  },
  watch: {
    $route (to, from) {
      // console.log(`Navigated from ${from.path} to ${to.path}`)
      // Add logic to handle path change
      const parts = to.path.split('/')
      this.pagaName = parts[parts.length - 1]
      this.ctaPath = this.pagaName
      if (this.pagaName === 'en' || this.pagaName === 'cn') {
        this.pagaName = 'home'
      }
    }
  },
  computed: {
    footerContent () {
    // Define content mapping object

      const homeContent = {
        positioning: 'left',
        dynamicText: 'Together',
        fixedText: ', We Thrive',
        endText: '',
        description: 'Tyng & Co is your end-to-end partner for compliance — and beyond.',
        ctaText: 'Contact Tyng & Co',
        ctaPath: 'contact-us'
      }

      const contentMap = {
        home: homeContent,
        services: {
          positioning: 'right',
          dynamicText: '4 Easy Steps',
          fixedText: 'After These&nbsp;',
          endText: '',
          description: 'We can offer our ongoing services to ensure you are always compliant & prepared for the tax season, while you focus on growing your business.<br><br class="d-lg-none">',
          ctaText: 'Register company now',
          ctaPath: 'contact-us?type=2'
        },
        deadline: {
          positioning: 'center',
          dynamicText: 'Ready',
          fixedText: 'We Are&nbsp;',
          endText: '&nbsp;To Help',
          description: 'Are you struggling with incorporation? Stressing about tax compliance and potential exorbitant penalties? Or maybe you want a true secretarial partner for your business? Perhaps you also need payroll guidance to ensure timely and accurate payments?',
          ctaText: 'Contact Tyng & Co',
          ctaPath: 'contact-us'
        },
        'management-team': {
          positioning: 'center',
          dynamicText: 'Secretarial & Tax',
          fixedText: 'Cutting-Edge&nbsp;',
          endText: '&nbsp;Consulting',
          description: 'Our team of certified experts offers a proactive approach to helping you and your business achieve your goals, <br class="d-none d-lg-inline-block">without the hassle or confusion of working with faceless firms or multiple individuals with limited knowledge.',
          ctaText: 'Contact Tyng & Co',
          ctaPath: 'contact-us'
        },
        'contact-us': {
          positioning: 'center',
          dynamicText: 'Simplify Success',
          fixedText: 'Let’s&nbsp;',
          endText: '&nbsp;Together',
          description: 'Contact us today to learn about our rates, discuss your business, or address your questions and concerns. We’re happy to help.<br><br class="d-lg-none">Ready to grow your business while we worry about your compliance?',
          ctaText: 'Contact Tyng & Co',
          ctaPath: 'self'
        },
        legal: homeContent,
        policy: homeContent
      }

      // Return content based on page name or default empty object
      return contentMap[this.pagaName] || {}
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
