<template>
    <div class="hero-banner" :style="{'height': height + 'px'}" v-bind:class="[shadow == 'true' ? 'banner-shadow' : '']">
      <swiper class="swiper h-100" :options="swiperOption">
        <swiper-slide class="tile">
          <!-- <img src="../assets/images/hero-banner/hanging-open.png" alt=""> -->
          <div v-if="bgPath" class="bg desktop" :style="{'background-image': 'url(' + require('@/'+bgPath) + ')'}"></div>
          <div v-if="bgPathMobile" class="bg mobile" :style="{'background-image': 'url(' + require('@/'+bgPathMobile) + ')'}"></div>
          <b-container class="d-flex relative h-100">
            <b-row no-gutters class="w-100">
              <b-col v-bind:class="[horizontal == 'right' ? 'ml-auto' : '', contentCol ? 'col-md-'+contentCol : 'col-8 col-md-8',] ">
                  <div class="content d-flex px-md-5 h-100" v-bind:class="[mVertical, dVertical]">
                      <div class="w-100" v-bind:class="textAlign">
                        <header-tile :content="title" :textAlign="textAlign"></header-tile>
                        <div class="description" v-html="description"></div>
                        <!-- <h1 v-html="title" v-bind:class="color"/> -->
                        <b-btn v-if="cta == 'true'" :to="`/${$i18n.locale}/contact-us`" class="btn-charcoal mr-auto mt-4">
                        <b>{{ $t('home.banner.cta') }}</b>
                        <fa-icon :icon="['fas', 'chevron-right']" class="icon ml-2"/>
                        </b-btn>
                        <!-- <div :style="{backgroundImage: 'url(' + bg2 + ')' }"></div> -->
                      </div>
                  </div>
              </b-col>
            </b-row>
          </b-container>
        </swiper-slide>
      </swiper>
    </div>
</template>

<script>

import headerTile from './headerTile.vue'

export default {
  name: 'hero-banner',
  components: {
    'header-tile': headerTile
  },
  data () {
    return {
      swiperOption: {
        touchRatio: 0
        // allowTouchMove: false
      }
    }
  },
  props: [
    'bgPath',
    'bgPathMobile',
    'height',
    'horizontal',
    'dVertical',
    'mVertical',
    'contentCol',
    'shadow',
    'textAlign',
    'title',
    'description',
    'cta',
    'color'
  ]
}
</script>

<style lang="scss" scoped>

</style>
