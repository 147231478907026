<template>
  <div class="capabilities-slider-container my-4">
    <div class="slider">
      <!-- First set of items -->
      <div class="slider-content">
        <div class="slider-item" v-for="item in capabilitiesListing" :key="item.id">
          <div class="icon"><fa-icon class="mr-2" :icon="['fas', item[`${$i18n.locale}`].icon]" color="black"/></div>
          <div class="content font-rage"><i>{{item[`${$i18n.locale}`].name}}</i></div>
        </div>
      </div>
      <!-- Duplicate set of items for seamless loop -->
      <div class="slider-content">
        <div class="slider-item" v-for="item in capabilitiesListing" :key="item.id">
          <div class="icon"><fa-icon class="mr-2" :icon="['fas', item[`${$i18n.locale}`].icon]" color="black"/></div>
          <div class="content font-rage"><i>{{item[`${$i18n.locale}`].name}}</i></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'capabilities-slider',
  data: function () {
    return {
      capabilitiesListing: []
    }
  },
  props: {
    // content: {
    //   type: String,
    //   required: true,
    //   default: ''
    // }
  },
  mounted () {
    this.fetchData(this.$i18n.locale)
  },
  methods: {
    async fetchData () {
      const res = await fetch('../data-lists/capabilities.json')
      const val = await res.json()
      this.capabilitiesListing = val
    }
  },
  computed: {
  },
  created () {
  },
  destroyed () {
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.capabilities-slider-container {
  width: 100%;
  overflow: hidden;
  padding: 20px 0;
  -webkit-mask-image: -webkit-linear-gradient(
    left,
    transparent 0%,
    black 10%,
    black 90%,
    transparent 100%
  );
  mask-image: linear-gradient(
    to right,
    transparent 0%,
    black 10%,
    black 90%,
    transparent 100%
  );
}

.slider {
  display: flex;
  width: fit-content;
  will-change: transform;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;

  &.paused {
    animation-play-state: paused;
  }
}

.slider-content {
  display: flex;
  gap: 30px;
  padding: 0 15px;
  transform: translate3d(0, 0, 0);
}

.slider-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 150px;
  padding: 20px 40px;
  white-space: nowrap;
  transform: translate3d(0, 0, 0);

  .icon {
    font-size: 22px;
    justify-content: center;
  }

  .content {
    font-size: 30px;
    @include media-breakpoint-up(lg) {
      font-size: 36px;
    }
  }
}

@keyframes scroll {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-50%, 0, 0);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .slider {
    animation: scroll 40s linear infinite;
  }
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .slider {
    animation-duration: 30s; // Slightly slower on mobile for smoother performance
  }

  .slider-item {
    min-width: 120px;
    padding: 15px;
  }
}

/* iOS specific fixes */
@supports (-webkit-touch-callout: none) {
  .slider {
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
  }
}
</style>
