<template>
  <div class="deadline">
    <hero-banner
      :bgPath="null"
      :bgPathMobile="null"
      height="800"
      horizontal="center"
      dVertical="d-center"
      mVertical="m-top"
      textAlign="text-center text-md-left"
      color=""
      contentCol="12"
      :title="$t('deadline.banner.title')"
      :description="$t('deadline.banner.description')"
      cta="false">
    </hero-banner>
    <container-style direction="style-right" bgColor="bg-white" :bgPath="null">
      <div class="container">
      <div>
        <h1>DEAD Line</h1>
        <header-tile content="DEADLINE"></header-tile>

          <b-row>
            <b-col>
              <div class="custom-dropdown">
                <multiselect class="form-control form-select" v-model="yearEndVal" :options="months" label="name" @input="onYearEndChange" :searchable="false" :close-on-select="true" :allow-empty="false">
                  <template slot="singleLabel" slot-scope="{ option }">{{option.name}}<fa-icon :icon="['fas', 'chevron-down']" color="gold" class="arrow-down"/></template>
                </multiselect>
              </div>
            </b-col>
            <b-col>
              <div class="custom-dropdown">
                <multiselect class="form-control form-select" v-model="anniversaryMonthVal" :options="months" label="name" @input="onMonthChange" :searchable="false" :close-on-select="true" :allow-empty="false">
                  <template slot="singleLabel" slot-scope="{ option }">{{option.name}}<fa-icon :icon="['fas', 'chevron-down']" color="gold" class="arrow-down"/></template>
                </multiselect>
              </div>
            </b-col>
            <b-col>
              <div class="custom-dropdown">
                <multiselect class="form-control form-select" v-model="anniversarydayVal" :options="filteredDays" label="name" @input="onDayChange" :searchable="false" :close-on-select="true" :allow-empty="false" placeholder="day">
                  <template slot="singleLabel" slot-scope="{ option }">{{option.name}}<fa-icon :icon="['fas', 'chevron-down']" color="gold" class="arrow-down"/></template>
                </multiselect>
                {{ anniversarydayVal }}
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <p>selected <b>Year END</b> :{{ yearEndVal }}</p>
              <p>selected <b>Anniversary Month</b> :{{ anniversaryMonthVal }}</p>
              <p>selected <b>Anniversary Day</b> :{{ anniversarydayVal }}</p>
              <p>Final Date after adding 30 days: {{ finalMonthAndDate }}</p> <!-- Display final date -->
              <!-- <p><strong>datelineDataListing</strong>: {{ datelineDataListing }}</p> -->
               <p><strong>getYearEndData</strong> {{ getYearEndData }}</p>
               <!-- <p>getselectedMonthData: {{ getselectedMonthData }}</p> -->
               <hr>
              <div v-for="(yearEndItem, index) in datelineDataListing" :key="index">
                <!-- {{ yearEndItem[yearEndVal.name] }} -->
                <div v-for="(eachMonth, index) in yearEndItem[yearEndVal.name]" :key="index">
                  <div class="mb-4 bg-gold">
                    <p>Month: <b>{{ eachMonth.month }}</b></p>
                    <div>
                      <h4>All Business</h4>
                      <div v-for="(business, index) in eachMonth.allBusiness" :key="index">
                        <div class="d-flex"><strong class="mr-4">{{ business.date }}</strong> {{ business.name }}</div>
                      </div>
                      <hr>
                    </div>
                    <div>
                      <h4>SDN BHD / LLP</h4>
                      <div v-for="(sdnbhdLlp, index) in eachMonth.sdnbhdLlp" :key="index">
                        <div class="d-flex"><strong class="mr-4">{{ sdnbhdLlp.date }}</strong> {{ sdnbhdLlp.name }}</div>
                      </div>
                      <hr>
                    </div>
                    <div v-if="eachMonth.solePropPartner.length">
                      <h4>SOLE PROP / PARTNERSHIP / INDIVIDUALS</h4>
                      <div v-for="(solePropPartner, index) in eachMonth.solePropPartner" :key="index">
                        <div class="d-flex"><strong class="mr-4">{{ solePropPartner.date }}</strong> {{ solePropPartner.name }}</div>
                      </div>
                      <hr>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
      </div>
    </div>
    </container-style>
  </div>
</template>

<script>
import heroBanner from '@/components/heroBanner.vue'
import containerStyle from '../components/containerStyle.vue'
import headerTile from '../components/headerTile.vue'

export default {
  name: 'deadline',
  components: {
    'hero-banner': heroBanner,
    'container-style': containerStyle,
    'header-tile': headerTile
  },
  data () {
    return {
      datelineDataListing: [],
      yearEndListing: [],
      newListing: [],
      yearEndVal: { val: '12', name: 'December' },
      anniversaryMonthVal: { val: null, name: 'MONTH' },
      anniversarydayVal: { val: null, name: 'DAY' },
      pickedData: { date: '01/01', name: 'CP700 Tax Instalment' },
      finaldate: {
        month: '',
        day: ''
      },
      months: [
        { val: '1', name: 'January' },
        { val: '2', name: 'February' },
        { val: '3', name: 'March' },
        { val: '4', name: 'April' },
        { val: '5', name: 'May' },
        { val: '6', name: 'June' },
        { val: '7', name: 'July' },
        { val: '8', name: 'August' },
        { val: '9', name: 'September' },
        { val: '10', name: 'October' },
        { val: '11', name: 'November' },
        { val: '12', name: 'December' }
      ],
      days: Array.from({ length: 31 }, (_, i) => ({
        val: (i + 1).toString(),
        name: (i + 1).toString()
      }))
    }
  },
  computed: {

    // getselectedMonthData () {
    //   const februaryData = this.getYearEndData[this.yearEndVal.name].find(item => item.month === 'February')
    //   return februaryData
    // },
    getYearEndData () {
      // Loop through the data array to find the month "December"
      // console.log('xxxxxxx ===', this.datelineDataListing.)
      const decemberData = this.datelineDataListing.find(item => Object.prototype.hasOwnProperty.call(item, this.yearEndVal.name))
      // const monthData = decemberData.find(item => item.month === 'February')
      const data = decemberData || {} // Ensure data is defined
      const cbd = data.December || [] // Provide a fallback empty array
      console.log('transformedData', cbd)
      return decemberData // Return null if December is not found
    },
    filteredDays () {
      if (!this.anniversaryMonthVal || !this.anniversaryMonthVal.val) return this.days
      const maxDays = this.getDaysInMonth(this.anniversaryMonthVal.val)
      return Array.from({ length: maxDays }, (_, i) => ({
        val: (i + 1).toString(),
        name: (i + 1).toString()
      }))
    },
    finalMonthAndDate () {
      const _this = this
      if (this.anniversaryMonthVal.val && this.anniversarydayVal.val) {
        // Create a date object based on the selected month and day
        const selectedDate = new Date(2025, this.anniversaryMonthVal.val - 1, this.anniversarydayVal.val)
        // console.log('selectedDate', selectedDate)
        // Add 30 days to the selected date
        selectedDate.setDate(selectedDate.getDate() + 29)
        // console.log('selectedDate.getDate() : ', selectedDate.getDate())

        // Get the new month and day after adding 30 days
        _this.finaldate.month = this.months[selectedDate.getMonth()]
        _this.finaldate.day = selectedDate.getDate()
        const toConvertDate = `${_this.finaldate.day} ${_this.finaldate.month.name}`
        _this.pickedData.date = _this.convertDateStringToNumeric(toConvertDate)
        console.log('converted date = ', _this.pickedData)

        return `${_this.finaldate.day} ${_this.finaldate.month.name}` // Return the final month and day
      }
      return null
    }
  },
  mounted () {
    this.fetchData(this.$i18n.locale)
  },
  methods: {
    async fetchData () {
      const res = await fetch('../data-lists/deadline-calendar.json')
      const val = await res.json()
      this.datelineDataListing = val

      // const decemberData = this.datelineDataListing.find(item => Object.prototype.hasOwnProperty.call(item, 'December'))
      // console.log('fect= ', decemberData)
    },
    onYearEndChange (value) {
      console.log('value =', value)
      // if (value.indexOf('Reset me!') !== -1) this.value = []
    },
    onMonthChange () {
      // Reset the selected day when the month changes
      this.anniversarydayVal = { val: null, name: 'DAY' }
    },
    onDayChange () {
      // const _this = this
      // console.log('day change: ', _this.finaldate.day)
    },
    getDaysInMonth (month) {
      const year = new Date().getFullYear() // Use the current year
      return new Date(year, parseInt(month), 0).getDate()
    },
    convertDateStringToNumeric (dateString) {
      const [day, monthName] = dateString.split(' ')
      const month = this.months.find(m => m.name === monthName)

      if (month) {
        return `${day.padStart(2, '0')}/${month.val.padStart(2, '0')}`
      } else {
        console.error(`Month "${monthName}" not found in the months array.`)
        return null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.deadline {
    .custom-dropdown{
        cursor: pointer;
        position: relative;
        .form-select {
            position: relative;
            ul {
                position: absolute;
                left: 0;
                top: 34px;
                background-color: white;
                list-style: none;
                margin: 0;
                padding: 0;
                width: 100%;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                border: 1px solid #ced4da;
                border-top-color: gold;
                li{
                    cursor: pointer;
                    &:hover {
                        background-color: gold;
                    }
                    .multiselect__option {
                        padding: 8px 15px;
                        width: 100%;
                        display: block;
                        &.multiselect__option--highlight {
                            background-color: gold;
                        }
                        &.multiselect__option--selected {
                          background: red;
                        }
                    }
                }
                /*@include media-breakpoint-up(md) {
                    top: 36px;
                }*/
            }
            .arrow-down{
                float: right;
                margin-top: 5px;
                margin-right: 15px;
            }
            &.multiselect--active {
                .arrow-down{
                   transform: rotate(180deg);
                }
            }
        }
    }
}

</style>
