<template>
  <div class="quick-links">
    <swiper class="swiper-quick-links-carousel pb-5 px-5  px-lg-3 mx-n3" :options="swiperOption">
      <swiper-slide class="tile" v-for="quickLinks in quickLinksListing" :key="quickLinks.id">
        <b-btn :to="`/${$i18n.locale}/`+quickLinks.path" class="card-quick-link btn-none">
          <div class="img-container">
            <div
              class="bg-img"
              :style="{'background-image': 'url(' + require(`@/${quickLinks.img}`) + ')'}"
            ></div>
          </div>
          <div class="content p-4">
            <h5 class="title" v-html="quickLinks[`${$i18n.locale}`].msg" />
            <div class="cta" :to="`/${$i18n.locale}/why-us`">{{ quickLinks[`${$i18n.locale}`].cta }}<fa-icon class="icon ml-2" :icon="['fas', 'chevron-circle-right']" color="goldenrod"/></div>
            <!-- <h3 v-html="$t(quickLinks[`${$i18n.locale}`].name)" /> -->
            <!-- <p>{{ $t(quickLinks[`${$i18n.locale}`].text) }}</p> -->
          </div>
        </b-btn>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'

export default {
  name: 'quick-links-carousal',
  data () {
    return {
      quickLinksListing: [],
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        watchOverflow: true,
        pagination: {
          el: '.swiper-pagination'
        },
        breakpoints: {
          320: {
            slidesPerView: 1
          },
          640: {
            slidesPerView: 2
          },
          1024: {
            slidesPerView: 3
          }
        }
      }
    }
  },
  mounted () {
    this.fetchData(this.$i18n.locale)
  },
  methods: {
    async fetchData () {
      const res = await fetch('../data-lists/quick-links-carousal.json')
      const val = await res.json()
      this.quickLinksListing = val
      //   this.rows = this.jobs.length
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
