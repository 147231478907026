<template>
  <div class="feature-tiles">
    <swiper ref="mySwiper" class="mx-n3 px-5" :options="swiperOption" @ready="onSwiperInit">
      <swiper-slide v-for="feature in featureListing" :key="feature.id">
        <b-col class="h-100 px-0 px-lg-3">
          <div
          img-alt="Feature-info"
          img-top
          tag="article"
          class="mb-2 h-100">
            <div class="feature px-4" role="tablist">
              <div class="feature-bg" :style="{'background-image': 'url(' + require('@/assets/images/services/'+feature.img) + ')'}"></div>
              <div class="triangle-bg-one"></div>
              <div class="triangle-bg-two"></div>
              <div class="position-relative h-100 d-flex flex-column">
                <div class="main-title">
                  <div><h4>{{feature[`${$i18n.locale}`].titleCn}}</h4></div>
                  <div class="title">
                    <h4 v-html="feature[`${$i18n.locale}`].titleEn"></h4>
                </div>
                </div>
                <div class="py-4 package">
                  <div v-for="item in feature[`${$i18n.locale}`].detials.package.items" :key="item.id">
                    <p class="d-flex text-left font-semiBold" v-if="item.msg">
                      <fa-icon :icon="['fas', 'check']" color="darkgoldenrod" class="mr-2 mt-1"/>
                      {{item.msg}}
                    </p>
                  </div>
                </div>
                <div v-if="feature[`${$i18n.locale}`].expand" no-body class="mb-1 d-none">
                  <div header-tag="header" role="tab">
                      <div block v-b-toggle="`accordion-1-${feature.id}`">
                        {{feature[`${$i18n.locale}`].expand}}
                        <fa-icon :icon="['fas', 'chevron-down']" color="gold" class="ml-2 arrow-down"/>
                        <fa-icon :icon="['fas', 'chevron-up']" color="gold" class="ml-2 arrow-up"/>
                      </div>
                  </div>
                  <b-collapse :id="`accordion-1-${feature.id}`" accordion="my-accordion" role="tabpanel">
                      <b-card-body>
                      <h3>{{feature[`${$i18n.locale}`].detials.package.title}}</h3>
                      <div v-for="item in feature[`${$i18n.locale}`].detials.package.items" :key="item.id">
                        <p class="d-flex">
                          <fa-icon :icon="['fas', 'check']" class="mr-2"/>
                          {{item.msg}}
                        </p>
                      </div>
                      <h3>{{feature[`${$i18n.locale}`].detials.addOns.title}}</h3>
                      <div v-for="item in feature[`${$i18n.locale}`].detials.addOns.items" :key="item.msg" v-bind:class="[item.highlight == 'true' ? 'highlight' : '']">
                        <p class="d-flex" v-if="feature[`${$i18n.locale}`].detials.addOns.title">
                          <fa-icon :icon="['fas', 'check']" class="mr-2"/>
                          {{item.msg}}
                        </p>
                      </div>
                      <!-- <b-card-text>I start opened because <code>visible</code> is <code>true</code></b-card-text>
                      <b-card-text>text</b-card-text> -->
                      </b-card-body>
                  </b-collapse>
                </div>
                <div v-if="feature[`${$i18n.locale}`].cta" class="cta">
                  <!-- <router-link v-if="feature.path" :to="`/${$i18n.locale}/${feature.path}`">{{feature[`${$i18n.locale}`].cta}}</router-link> -->
                  <b-btn v-if="feature.path" class="btn-charcoal mx-auto mb-4 btn-secondary" :to="`/${$i18n.locale}/${feature.path}`"><b>{{feature[`${$i18n.locale}`].cta}}</b></b-btn>
                  <b-btn v-else class="btn-charcoal mx-auto mb-4 btn-secondary" @click="scrollToStep"><b>{{feature[`${$i18n.locale}`].cta}}</b></b-btn>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </swiper-slide>
      <div class="swiper-button-prev d-none d-lg-block" slot="button-prev"></div>
      <div class="swiper-button-next d-none d-lg-block" slot="button-next"></div>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>

export default {
  name: 'feature-tile',
  data () {
    return {
      featureListing: [],
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        // loop: true,
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false
        // },
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            loop: true,
            autoplay: {
              delay: 3000,
              disableOnInteraction: false
            }
          },
          640: {
            slidesPerGroup: 2,
            slidesPerView: 2
          },
          991: {
            slidesPerGroup: 3,
            spaceBetween: 35,
            allowTouchMove: false
          }
        }
      }
    }
  },
  mounted () {
    this.fetchData(this.$i18n.locale)

    if (this.swiper) {
      console.log('Swiper instance:', this.swiper)
      // this.swiper.slideTo(4, 500)
    }
  },
  methods: {
    async fetchData () {
      const res = await fetch('../data-lists/feature.json')
      const val = await res.json()
      this.featureListing = val
      this.onSwiperInit()
    },
    onSwiperInit () {
      // Swiper instance is now available
      const swiper = this.$refs.mySwiper.$swiper
      console.log('Swiper instance:', swiper)
      if (swiper) {
        const type = this.$route.query.type
        if (type) {
          swiper.slideTo(type, 500)
        }
      }
    },
    scrollToStep () {
      const element = document.getElementById('step-container')
      if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset
        window.scrollTo({
          top: elementPosition - 100, // Subtract 50px for the offset
          behavior: 'smooth'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
