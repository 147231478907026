<template>
  <div class="certificate">
    <div class="grid-container">
      <div v-for="item in certificateListing" :key="item.id" class="grid-item certificate-logo">
        <img :src="require('@/'+item.imagePath)" :alt="item.alt">
      </div>

      <!-- <div class="grid-item certificate-logo">
        <img src="../assets/images/home/MIA_w_trans.png" alt="">
      </div>
      <div class="grid-item certificate-logo">
        <img src="../assets/images/home/LHDN.png" alt="">
      </div>
      <div class="grid-item certificate-logo">
        <img src="../assets/images/home/CTIM_w_trans.png" alt="">
      </div>
      <div class="grid-item certificate-logo">
        <img src="../assets/images/home/SSM_w_trans.png" alt="">
      </div>
      <div class="grid-item certificate-logo">
        <img src="../assets/images/home/HRDC.png" alt="">
      </div> -->
    </div>
  </div>
</template>

<script>

export default {
  name: 'certificate',
  data: function () {
    return {
      certificateListing: []
    }
  },
  props: {
    // content: {
    //   type: String,
    //   required: true,
    //   default: ''
    // }
  },
  computed: {
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      const res = await fetch('../data-lists/certificate.json')
      const val = await res.json()
      this.certificateListing = val
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.certificate {
  width: 100%;
  .grid-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    @include media-breakpoint-up(lg) {
      gap: 10px;
    }
    .grid-item {
      padding: 10px;
      text-align: center;
      position: relative;
      min-height: 80px;
      @include media-breakpoint-up(lg) {
        min-height: 170px;
      }
      &::before {
        content: "";
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background:  #e4dfd1cf;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10px;
        @include media-breakpoint-up(lg) {
          left: 42px;
          width: 100px;
          height: 100px;
        }
      }
      img {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        max-width: 75%;
        @include media-breakpoint-up(lg) {
          right: 16px;
          max-width: 150px;
        }
      }
    }
  }
}
</style>
