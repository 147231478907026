<template>
  <div>
      <GmapMap
        :center="{lat:3.148020, lng:101.715035}"
        :zoom="20"
        style="width: 100%; height: 500px"
        >
        <GmapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="google && new google.maps.LatLng(3.148020, 101.715035)"
            :clickable="false"
            :draggable="false"
            @click="mapclick()"
        />
      </GmapMap>
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps'
export default {
  name: 'google-map',
  computed: {
    google: gmapApi
  },
  data () {
    return {
      center: { lat: 10.0, lng: 10.0 },
      markers: [{
        position: {
          lat: 0,
          lng: 0
        }
      }],
      search: '',
      meteoriteList: []
    }
  },
  mounted () {
    this.$refs.mapRef.$mapPromise.then((map) => {
      map.panTo({ lat: 3.148020, lng: 101.715035 })
    })
  },
  methods: {
    mapclick () {
      console.log('map click')
    }
  }
}

</script>

<style>

</style>
