<template>
  <div class="tag">
    {{ content }}
  </div>
</template>

<script>
export default {
  name: 'tag',
  data: function () {
    return {

    }
  },
  props: {
    content: {
      type: String,
      required: true,
      default: ''
    }
  },
  computed: {
  },
  created () {
  },
  destroyed () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.tag{
  font-size: 14px;
  font-weight: 500;
  border-radius: 30px;
  border: 2px solid goldenrod;
  padding: 4px 16px;
  display: inline-block;
}
</style>
