<template>
  <div>
    <hero-banner
      :bgPath="`assets/images/hero-banner/contact_us_city_view.png`"
      :bgPathMobile="`assets/images/hero-banner/answer-call-mobile.png`"
      height="800"
      textAlign="center"
      horizontal="center"
      dVertical="d-center-center"
      mVertical="m-center-center"
      color="m-white-d-charcoal"
      shadow="true"
      contentCol="12"
      :title="$t('contact-us.banner.title')"
      cta="">
    </hero-banner>

    <container-style id="form-container" direction="style-right" bgColor="bg-white" :bgPath="null">
      <b-container class="contact-us">
        <b-row class="no-gutters">
          <div class="contact-form pb-5 p-lg-5">
            <h4 class="title pb-4 pb-lg-5">
              {{ $t('contact-us.userForm.prompt') }}
            </h4>
            <div class="thank-you py-5 my-5" v-if="submitStatus === 'success'">
                <div class="thank-you-wrapper">
                  <fa-icon class="icon" :icon="['fa', 'check-circle']" color="white"/>
                  {{ $t('contact-us.userForm.status.success') }}
                </div>
            </div>
            <div class="sumbit-failed text-center py-5 my-5" v-if="submitStatus === 'failed'">
              <div class="sumbit-failed-wrapper">
                <div class="d-flex flex-column justify-content-center align-items-center mb-2">
                  <fa-icon class="icon" :icon="['fa', 'exclamation-circle']" color="white"/>
                  <span v-html="$t('contact-us.userForm.status.fail')"></span>
                </div>
                <div>
                  <button class="btn btn-retry px-5 py-2" type="button" @click="retry()">
                    <b><fa-icon class="mr-2" :icon="['fa', 'undo-alt']" color="white"/>{{$t('contact-us.userForm.ctaRetry')}}</b>
                  </button>
                </div>
              </div>
            </div>
            <!-- <div class="py-4 mt-5">{{ $t('contact-us.userForm.prompt') }}</div> -->
            <form v-if="!(submitStatus == 'failed' ||  submitStatus == 'success')" @submit.prevent="checkForm">

                <div class="form-group">
                  <label class="form-label">{{ $t('contact-us.userForm.cRequest') }}</label>
                  <div class="custom-dropdown">
                    <multiselect class="form-control form-select" v-model="serviceRequestName" :options="$t('contact-us.userForm.cRquest')" label="typeName" @input="onChange" :searchable="false" :close-on-select="true" :allow-empty="false">
                      <template slot="singleLabel" slot-scope="{ option }">{{$t('contact-us.userForm.cRquest['+option.val+'].typeName')}}<fa-icon :icon="['fas', 'chevron-down']" color="darkgoldenrod" class="arrow-down"/></template>
                    </multiselect>
                  </div>
                  <!-- visible value for final submittion -->
                  <div class="requestType-val d-none" :data-val="$t('contact-us.userForm.cRquest['+serviceRequestName.val+'].typeName')">{{$t('contact-us.userForm.cRquest['+serviceRequestName.val+'].typeName')}}</div>
                </div>

                <div class="form-group" :class="{ 'form-group--error': $v.form.name.$error }">
                  <label class="form-label">{{ $t('contact-us.userForm.cName') }}</label>
                  <input type="text" class="form-control" v-model.trim="$v.form.name.$model" placeholder="e.g. Sam" onfocus="this.placeholder=''" onblur="this.placeholder='e.g. Sam'"/>
                  <div v-if="$v.form.name.$dirty">
                    <div class="error" v-if="!$v.form.name.required">{{ $t('contact-us.userForm.errorMsg.required') }}</div>
                    <!-- <div class="error" v-if="!$v.form.name.minLength">Name must have at least {{$v.form.name.$params.minLength.min}} letters.</div> -->
                  </div>
                </div>

                <div class="form-group" :class="{ 'form-group--error': $v.form.clientEmail.$error }">
                  <label class="form-label">{{ $t('contact-us.userForm.cEmail') }}</label>
                  <input type="text" class="form-control" v-model.trim="$v.form.clientEmail.$model" placeholder="e.g. sam@email.com" onfocus="this.placeholder=''" onblur="this.placeholder='e.g. sam@email.com'"/>
                  <div v-if="$v.form.name.$dirty">
                    <div class="error" v-if="!$v.form.clientEmail.required">{{ $t('contact-us.userForm.errorMsg.required') }}</div>
                    <div class="error" v-if="!$v.form.clientEmail.email">{{ $t('contact-us.userForm.errorMsg.emailInvalid') }}</div>
                  </div>
                </div>

                <div class="form-group" :class="{ 'form-group--error': $v.form.clientContact.$error }">
                  <label class="form-label">{{ $t('contact-us.userForm.cContact') }}</label>
                  <input type="tel" class="form-control" v-model.trim="$v.form.clientContact.$model" placeholder="e.g. 0123456789" onfocus="this.placeholder=''" onblur="this.placeholder='e.g. 0123456789'"/>
                  <div v-if="$v.form.name.$dirty">
                    <div class="error" v-if="!$v.form.clientContact.required">{{ $t('contact-us.userForm.errorMsg.required') }}</div>
                    <div class="error" v-if="!$v.form.clientContact.numeric">{{ $t('contact-us.userForm.errorMsg.mobileInvalid') }}</div>
                    <div class="error" v-if="!$v.form.clientContact.minLength">{{ $t('contact-us.userForm.errorMsg.mobileFormat') }}</div>
                    <div class="error" v-if="!$v.form.clientContact.maxLength">{{ $t('contact-us.userForm.errorMsg.mobileFormat') }}</div>
                  </div>
                </div>

                <div class="form-group">
                  <label class="form-label">{{ $t('contact-us.userForm.cMessage') }}</label>
                  <textarea v-model="form.clientMessage" name="your-message" cols="40" rows="10" class="form-control text-message" aria-invalid="false" :placeholder="$t('contact-us.userForm.cPlaceholderMessage')" onfocus="this.placeholder=''" :onblur="`this.placeholder='`+$t('contact-us.userForm.cPlaceholderMessage')+`'`"></textarea>
                </div>

                <div class="d-flex justify-content-center">
                  <button class="btn btn-submit px-5 py-2" type="submit" :disabled="submitStatus === 'pending'">
                    <b>
                      <div v-if="submitStatus !== 'pending'">{{$t('contact-us.userForm.cta')}}</div>
                      <div v-else>{{ $t('contact-us.userForm.status.pending') }}</div>
                    </b>
                  </button>
                </div>
                <!-- <button class="btn btn-submit" type="submit" :disabled="submitStatus === 'PENDING'">{{submitStatus}}</button> -->
            </form>

            <transition name="fade" mode="out-in">
                <article class="message is-primary" v-show="showSubmitFeedback">
                    <div class="message-header">
                        <p>Fake Send Status:</p>
                    </div>
                    <div class="message-body">
                        Successfully Submitted!
                    </div>
                </article>
            </transition>

            <div class="d-none">
              <h5>
                  JSON
              </h5>
              <!-- <div>{{$t('contact-us.userForm.cRquest['+form.serviceRequest+'].typeName')}}</div> -->
              <pre><code>{{$t('contact-us.userForm.cRquest['+serviceNum+'].typeName')}}</code></pre>
              <!-- <pre><code>{{serviceRequestName}}</code></pre> -->
              <pre><code>{{form}}</code></pre>
            </div>
          </div>
        </b-row>
      </b-container>
    </container-style>

    <container-style direction="style-left" bgColor="bg-white" :bgPath="null">
      <b-container>
        <!-- <div style="position: absolute;opacity: 0.04;width: 120%;height: 120%;right: -25%;rotate: 20deg;top: -23%;">
          <fa-icon class="w-100 h-100" :icon="['fas', 'map-marker-alt']" color="gray"/>
        </div>
        <div style="position: absolute;opacity: 0.04;width: 60%;height: 60%;rotate: 0deg;top: 40%;right: 57%;">
          <fa-icon class="w-100 h-100" :icon="['fas', 'phone-alt']" color="gray"/>
        </div>
        <div style="position: absolute;opacity: 0.04;width: 30%;height: 30%;rotate: -20deg;top: 5%;right: 69%;">
          <fa-icon class="w-100 h-100" :icon="['fas', 'envelope']" color="gray"/>
        </div> -->
        <b-row class="no-gutters">
          <b-col>
            <div class="company-details">
              <b-row>
              <b-col>
                <div class="name">
                  <div class="align-items-center d-flex">
                    <h4 class="mr-2 mb-0">{{ $t('contact-us.company.name') }} </h4><h6 class="mb-0"> {{ $t('contact-us.company.registerNum') }}</h6>
                  </div>
                  <h5 class="mb-3" v-if="$t('contact-us.company.natureEN')" v-html="$t('contact-us.company.natureEN')"/>
                  <h4 class="mb-3" v-if="$t('contact-us.company.natureCN')" v-html="$t('contact-us.company.natureCN')" />
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <div class="py-3">
                  <b-row class="contact-number">
                    <b-col class="col-12">
                      <a class="font-semiBold" :href="`tel:`+$t('contact-us.company.phone.num')"><div class="icon"><fa-icon :icon="['fas', 'phone-alt']" color="darkgoldenrod"/></div>{{ $t('contact-us.company.phone.num') }}</a>
                    </b-col>
                    <b-col class="email col-12 mt-2 mt-lg-0">
                      <a class="font-semiBold" :href="`mailto:`+$t('contact-us.company.email.address')"><div class="icon"><fa-icon :icon="['fas', 'envelope']" color="darkgoldenrod"/></div>{{ $t('contact-us.company.email.address') }}</a>
                    </b-col>
                  </b-row>
                  <div class="address d-flex mt-2 mt-lg-0">
                    <div>
                      <div class="d-flex font-semiBold">
                        <div class="icon"><fa-icon :icon="['fas', 'map-marker-alt']" color="darkgoldenrod"/></div>
                        <span v-if="$t('contact-us.company.address.title')">{{ $t('contact-us.company.address.title') }}<br></span> {{ $t('contact-us.company.address.add1') }} {{ $t('contact-us.company.address.add2') }} <br class="d-none"> {{ $t('contact-us.company.address.add3') }} {{ $t('contact-us.company.address.add4') }}
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <!-- <div class="my-5 pb-5">
              <g-maps></g-maps>
            </div> -->
          </div>
          </b-col>
        </b-row>

        <b-row class="justify-content-center mt-lg-4">
            <b-col>
              <a class="map-link" href="https://www.google.com/maps/place/Tyng+%26+Co+%E2%80%94+Tax+Agent+%7C+CoSec+%7C+Accounting+%7C+Register+New+Companies/@3.1480192,101.7124546,17z/data=!3m1!4b1!4m6!3m5!1s0x31cc37390533c7c9:0x30b6e944cbdd8ef8!8m2!3d3.1480192!4d101.7150349!16s%2Fg%2F11jrt9_2y8?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D" target="_blank">
                <b-btn class="btn-charcoal mr-auto my-4 d-md-none">
                  <fa-icon class="mr-2" :icon="['fas', 'map-marked']" color="darkgoldenrod"/> Find us at Google Map
                </b-btn>
              </a>
              <a class="map-link" href="https://www.google.com/maps/place/Tyng+%26+Co+%E2%80%94+Tax+Agent+%7C+CoSec+%7C+Accounting+%7C+Register+New+Companies/@3.1480192,101.7124546,17z/data=!3m1!4b1!4m6!3m5!1s0x31cc37390533c7c9:0x30b6e944cbdd8ef8!8m2!3d3.1480192!4d101.7150349!16s%2Fg%2F11jrt9_2y8?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D" target="_blank">
                <div class="map-location d-none d-md-block position-relative" :style="{'background-image': 'url(' + require('@/assets/images/contact-us/map-location.png') + ')'}">
                  <div class="location-card">
                    <img src="https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid=hVeZLqP7SN8BHxny8fdqQg&cb_client=search.gws-prod.gps&w=408&h=240&yaw=165.76817&pitch=0&thumbfov=100">
                    <div class="detail">
                      <p>Tyng & Co</p>
                      <div>Tax Agent | CoSec | Accounting | Register New Companies</div>
                    </div>
                  </div>
                 </div>
                </a>
            </b-col>
        </b-row>

      </b-container>
    </container-style>

    <!-- backup waiy for cleanup -->
    <div class="contact-us container py-lg-5 d-none">
      <!-- <message-form></message-form> -->
      <div class="contact-form p-lg-5">
        <h4 class="title py-5">
          {{ $t('contact-us.userForm.prompt') }}
        </h4>
        <div class="thank-you py-5 my-5" v-if="submitStatus === 'success'">
          <fa-icon class="icon mr-2" :icon="['fa', 'check-circle']" color="limegreen"/>
          {{ $t('contact-us.userForm.status.success') }}
        </div>
        <div class="sumbit-failed d-block text-center py-5 my-5" v-if="submitStatus === 'failed'">
          <div class="d-flex justify-content-center align-items-center mb-2">
            <fa-icon class="icon mr-2" :icon="['fa', 'exclamation-circle']" color="tomato"/>
            {{ $t('contact-us.userForm.status.fail') }}
          </div>
          <div>
            <button class="btn btn-retry mt-3 px-5 py-2" type="button" @click="retry()">
              <b><fa-icon class="mr-2" :icon="['fa', 'undo-alt']" color="white"/>{{$t('contact-us.userForm.ctaRetry')}}</b>
            </button>
          </div>
        </div>
        <!-- <div class="py-4 mt-5">{{ $t('contact-us.userForm.prompt') }}</div> -->
        <form v-if="!(submitStatus == 'failed' ||  submitStatus == 'success')" @submit.prevent="checkForm">

            <div class="form-group">
              <label class="form-label">{{ $t('contact-us.userForm.cRequest') }}</label>
              <div class="custom-dropdown">
                <multiselect class="form-control form-select" v-model="serviceRequestName" :options="$t('contact-us.userForm.cRquest')" label="typeName" @input="onChange" :searchable="false" :close-on-select="true" :allow-empty="false">
                  <template slot="singleLabel" slot-scope="{ option }">{{$t('contact-us.userForm.cRquest['+option.val+'].typeName')}}<fa-icon :icon="['fas', 'chevron-down']" color="gold" class="arrow-down"/></template>
                </multiselect>
              </div>
              <!-- visible value for final submittion -->
              <div class="requestType-val d-none" :data-val="$t('contact-us.userForm.cRquest['+serviceRequestName.val+'].typeName')">{{$t('contact-us.userForm.cRquest['+serviceRequestName.val+'].typeName')}}</div>
            </div>

            <div class="form-group" :class="{ 'form-group--error': $v.form.name.$error }">
              <label class="form-label">{{ $t('contact-us.userForm.cName') }}</label>
              <input type="text" class="form-control" v-model.trim="$v.form.name.$model" placeholder="e.g. Sam" onfocus="this.placeholder=''" onblur="this.placeholder='e.g. Sam'"/>
              <div v-if="$v.form.name.$dirty">
                <div class="error" v-if="!$v.form.name.required">{{ $t('contact-us.userForm.errorMsg.required') }}</div>
                <!-- <div class="error" v-if="!$v.form.name.minLength">Name must have at least {{$v.form.name.$params.minLength.min}} letters.</div> -->
              </div>
            </div>

            <div class="form-group" :class="{ 'form-group--error': $v.form.clientEmail.$error }">
              <label class="form-label">{{ $t('contact-us.userForm.cEmail') }}</label>
              <input type="text" class="form-control" v-model.trim="$v.form.clientEmail.$model" placeholder="e.g. sam@email.com" onfocus="this.placeholder=''" onblur="this.placeholder='e.g. sam@email.com'"/>
              <div v-if="$v.form.name.$dirty">
                <div class="error" v-if="!$v.form.clientEmail.required">{{ $t('contact-us.userForm.errorMsg.required') }}</div>
                <div class="error" v-if="!$v.form.clientEmail.email">{{ $t('contact-us.userForm.errorMsg.emailInvalid') }}</div>
              </div>
            </div>

            <div class="form-group" :class="{ 'form-group--error': $v.form.clientContact.$error }">
              <label class="form-label">{{ $t('contact-us.userForm.cContact') }}</label>
              <input type="tel" class="form-control" v-model.trim="$v.form.clientContact.$model" placeholder="e.g. 0123456789" onfocus="this.placeholder=''" onblur="this.placeholder='e.g. 0123456789'"/>
              <div v-if="$v.form.name.$dirty">
                <div class="error" v-if="!$v.form.clientContact.required">{{ $t('contact-us.userForm.errorMsg.required') }}</div>
                <div class="error" v-if="!$v.form.clientContact.numeric">{{ $t('contact-us.userForm.errorMsg.mobileInvalid') }}</div>
                <div class="error" v-if="!$v.form.clientContact.minLength">{{ $t('contact-us.userForm.errorMsg.mobileFormat') }}</div>
                <div class="error" v-if="!$v.form.clientContact.maxLength">{{ $t('contact-us.userForm.errorMsg.mobileFormat') }}</div>
              </div>
            </div>

            <div class="form-group">
              <label class="form-label">{{ $t('contact-us.userForm.cMessage') }}</label>
              <textarea v-model="form.clientMessage" name="your-message" cols="40" rows="10" class="form-control text-message" aria-invalid="false" :placeholder="$t('contact-us.userForm.cPlaceholderMessage')" onfocus="this.placeholder=''" :onblur="`this.placeholder='`+$t('contact-us.userForm.cPlaceholderMessage')+`'`"></textarea>
            </div>

            <button class="btn btn-submit mt-3 px-5 py-2" type="submit" :disabled="submitStatus === 'pending'">
              <b>
                <div v-if="submitStatus !== 'pending'">{{$t('contact-us.userForm.cta')}}</div>
                <div v-else>{{ $t('contact-us.userForm.status.pending') }}</div>
              </b>
            </button>
            <!-- <button class="btn btn-submit" type="submit" :disabled="submitStatus === 'PENDING'">{{submitStatus}}</button> -->
        </form>

        <transition name="fade" mode="out-in">
            <article class="message is-primary" v-show="showSubmitFeedback">
                <div class="message-header">
                    <p>Fake Send Status:</p>
                </div>
                <div class="message-body">
                    Successfully Submitted!
                </div>
            </article>
        </transition>

        <div class="d-none">
          <h5>
              JSON
          </h5>
          <!-- <div>{{$t('contact-us.userForm.cRquest['+form.serviceRequest+'].typeName')}}</div> -->
          <pre><code>{{$t('contact-us.userForm.cRquest['+serviceNum+'].typeName')}}</code></pre>
          <!-- <pre><code>{{serviceRequestName}}</code></pre> -->
          <pre><code>{{form}}</code></pre>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import heroBanner from '@/components/heroBanner.vue'
import { required, minLength, maxLength, email, numeric } from 'vuelidate/lib/validators'
import containerStyle from '../components/containerStyle.vue'
// import gmap from '@/components/gmap.vue'

function submitScroll () {
  $('html, body').animate({
    scrollTop: $('.contact-form').offset().top - 50
  }, 500)
}

export default {
  name: 'contact-us',
  metaInfo () {
    console.log('metaInfo being called in Contact-Us')
    const title = 'Tyng & Co | Contact Us'
    const description = 'Get Elite Solutions for Your SME'
    document.querySelector('meta[name="description"]').content = description
    return {
      title,
      titleTemplate: null,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: description
        }
      ],
      link: [{
        rel: 'canonical',
        href: `https://tyng-ca.com.my/${this.$i18n.locale}/contact-us`
      }]
    }
  },
  components: {
    'hero-banner': heroBanner,
    'container-style': containerStyle
    // 'g-maps': gmap
    // 'message-form': messageForm
  },
  data () {
    return {
      value: null,
      options: ['list', 'of', 'options'],
      serviceNum: 0,
      serviceRequestName: { val: '0', typeName: 'General Enquiry' },
      serviceRequestType: null,
      form: {
        formName: 'Tell Us About Yourself',
        name: '',
        clientEmail: '',
        clientContact: '',
        clientMessage: '',
        serviceRequest: '1'
        // favoriteHamburger: '',
        // favoriteHangout: [],
        // workHours: 0
      },
      formData: {
        subject: null,
        body: null,
        altBody: 'This is the body in plain text for non-HTML mail clients'
      },
      submitStatus: null,
      showSubmitFeedback: false,
      serviceList: ['General Enquiry', 'Enquire About Services', 'Get A Quote', 'Book An Appointment']
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      clientEmail: {
        required,
        email
      },
      clientContact: {
        minLength: minLength(10),
        maxLength: maxLength(11),
        required,
        numeric
      }
    }
  },
  watch: {
    serviceRequestName: {
      handler (newValue, oldValue) {
        console.log('newValue ===', newValue)
        if (newValue.val === '2') {
          this.form.clientMessage = 'I would like to register a Sdn Bhd. Please contact me.'
        } else if (newValue.val === '3') {
          this.form.clientMessage = 'I would like to convert my enterprise to Sdn Bhd. Please contact me.'
        } else {
          this.form.clientMessage = ''
        }
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      console.log('Meta description:', document.querySelector('meta[name="description"]')?.content)
      const type = this.$route.query.type
      if (type && type === '1') {
        this.serviceRequestName.val = `${type}`
        this.form.clientMessage = 'I would like to switch cosec at no charge. Please contact me.'
        this.scrollToForm()
      }
      if (type && type === '2') {
        this.serviceRequestName.val = `${type}`
        this.form.clientMessage = 'I would like to register a Sdn Bhd. Please contact me.'
        this.scrollToForm()
      }
    })
  },
  methods: {
    onChange (value) {
      console.log('value =', value)
      // if (value.indexOf('Reset me!') !== -1) this.value = []
    },
    checkForm () {
      // console.log($('.requestType-val').text())
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'error'
        console.log('form error')
      } else {
        // do your submit logic here
        this.submitStatus = 'pending'
        console.log('form peding')
        this.formSubmit()
        // setTimeout(() => {
        //   this.submitStatus = 'OK'
        // }, 500)
      }
    },
    formSubmit () {
      var _this = this
      this.serviceRequestType = $('.requestType-val').text()
      this.formData.subject = this.form.formName
      this.formData.body = '<b>' + this.$t('contact-us.userForm.cRequest') + ': </b>' + this.serviceRequestType + '<br><b>' + this.$t('contact-us.userForm.cName') + ': </b> ' + this.form.name + '<br>' + '<b>' + this.$t('contact-us.userForm.cEmail') + ': </b> ' + this.form.clientEmail + '<br>' + '<b>' + this.$t('contact-us.userForm.cContact') + ': </b> ' + this.form.clientContact + '<br><b>' + this.$t('contact-us.userForm.cMessage') + ': </b>' + this.form.clientMessage
      $.ajax({
        type: 'POST',
        url: '../submit.php',
        data: JSON.stringify(this.formData),
        contentType: 'application/json',
        success: function () {
          $('.success').fadeIn(1000)
        }
      }).done(function (resp) {
        console.log('sucess = ', resp)
        _this.submitStatus = 'success'
        submitScroll()
      }).fail(function (resp) {
        console.log('fail = ', resp)
        _this.submitStatus = 'failed'
        submitScroll()
      })
    },
    typeChange (e) {
      console.log('change')
    },
    retry () {
      this.submitStatus = null
    },
    scrollToForm () {
      const element = document.getElementById('form-container')
      if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset
        window.scrollTo({
          top: elementPosition - 100, // Subtract 50px for the offset
          behavior: 'smooth'
        })
      }
    }
  }
  // watch: {
  //   serviceNum: function (val) {
  //     this.serviceRequestName = this.$t('contact-us.userForm.cRquest[' + this.serviceNum + '].typeName')
  //     console.log('watch change', this.serviceRequestName)
  //   }
  // }
}
</script>

<style lang="scss" scoped>
    .fade-enter, .fade-leave-active{
        opacity: 0;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
</style>
