import { render, staticRenderFns } from "./Why-Us.vue?vue&type=template&id=508b5cc4&scoped=true"
import script from "./Why-Us.vue?vue&type=script&lang=js"
export * from "./Why-Us.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "508b5cc4",
  null
  
)

export default component.exports