<template>
  <h2 class="header-tile" :class="`text-${textAlign} ${className}`" v-html="content">
  </h2>
</template>

<script>
export default {
  name: 'tag',
  data: function () {
    return {

    }
  },
  props: {
    content: {
      type: String,
      required: true,
      default: ''
    },
    textAlign: {
      type: String,
      required: false,
      default: 'left'
    },
    className: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
  },
  created () {
  },
  destroyed () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>

@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.header-tile{
  width: 100%;
  font-size: 32px;
  line-height: 1.25;
  &.text-center {
    text-align: center;
  }
  &.text-left {
    text-align: left;
  }
  &.text-right {
    text-align: right;
  }

  @include media-breakpoint-up(lg) {
    font-size: 36px;
  }

  &.sub-title {
    font-size: 28px;
    @include media-breakpoint-up(lg) {
      font-size: 36px;
    }
  }
}
</style>
