<template>
 <div>
    <div class="social-quick-link">
      <!-- <div class="messanger">
        <a href="https://m.me/TyngCA" target="_blank">
            <fa-icon class="icon" :icon="['fab', 'facebook-messenger']" color="white"/>
        </a>
      </div> -->
      <div class="whats-app">
          <a :href="`https://`+platform+`.whatsapp.com/send?phone=60109099928`" target="_blank">
              <fa-icon class="icon" :icon="['fab', 'whatsapp']" color="white"/>
          </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'whats-app',
  data: function () {
    return {
      platform: 'api',
      window: {
        width: 0,
        height: 0
      }
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
      if (this.window.width > 768) {
        this.platform = 'web'
      } else {
        this.platform = 'api'
      }
      // console.log(this.platform)
    }
  }
}
</script>

<style>

</style>
