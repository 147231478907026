<template>
  <div class="explore-services-box-group">
    <div class="box-container">
      <router-link :to="`/${$i18n.locale}/services?type=0`" class="item order-1">Company Incorporation<fa-icon class="icon" :icon="['fas', 'long-arrow-alt-right']" color="white"/></router-link>
      <router-link :to="`/${$i18n.locale}/services?type=2`" class="item order-3 order-lg-2">Tax<fa-icon class="icon" :icon="['fas', 'long-arrow-alt-right']" color="white"/></router-link>
      <router-link :to="`/${$i18n.locale}/services?type=4`" class="item order-5 order-lg-3">Payroll Processing<fa-icon class="icon" :icon="['fas', 'long-arrow-alt-right']" color="white"/></router-link>
      <router-link :to="`/${$i18n.locale}/services?type=1`" class="item order-2 order-lg-4">Company Secretarial<fa-icon class="icon" :icon="['fas', 'long-arrow-alt-right']" color="white"/></router-link>
      <router-link :to="`/${$i18n.locale}/services?type=5`" class="item order-4 order-lg-5">Training<fa-icon class="icon" :icon="['fas', 'long-arrow-alt-right']" color="white"/></router-link>
      <router-link :to="`/${$i18n.locale}/services?type=3`" class="item order-6">Employer Registration<fa-icon class="icon" :icon="['fas', 'long-arrow-alt-right']" color="white"/></router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'services-box',
  data: function () {
    return {

    }
  },
  props: {
    // content: {
    //   type: String,
    //   required: true,
    //   default: ''
    // }
  },
  computed: {
  },
  created () {
  },
  destroyed () {
  },
  methods: {
    // navigate () {
    //   this.$router.push(`/${this.$i18n.locale}/services?type=training`)
    // }
  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.explore-services-box-group {
    // width: 50%;
    display: flex;
    // position: absolute;
    right: 0;
    top: 0;
    // height: calc(100% - 200px);
    height: 100%;
    .box-container {
      font-size: 18px;
      font-weight: 700;
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* 2 columns */
      grid-template-rows: repeat(3, 1fr); /* 3 rows */
      gap: 16px; /* Optional: Adds spacing between grid items */
      width: 100%; /* Adjust width as needed */
      height: 100%; /* Adjust height as needed */
      @include media-breakpoint-up(lg) {
        grid-template-rows: auto auto; /* Defines two rows, both with automatic height */
        grid-template-columns: 1fr 1fr 1fr; /* Defines three equal columns */
      }
      .item {
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        border-radius: 30px;
        text-align: center;
        padding: 20px;
        min-height: 150px;
        transition: background-color 0.5s ease;
        @include media-breakpoint-up(lg) {
          min-height: 180px;
        }
        &:hover {
          text-decoration: none;
          color: black;
          .icon {
            color: black;
            transform: translateX(10px);
          }
        }
        .icon {
          position: absolute;
          right: 34px;
          bottom: 12px;
          transition: all 0.5s ease;
        }
      }
      .item:first-child,
      .item:last-child {
        background-color: rgba(52, 58, 64, 0.5);
        &:hover {
          background-color: goldenrod;
        }
      }
      .item:nth-child(2),
      .item:nth-child(4) {
        background-color: rgba(108, 117, 125, 0.5);
        &:hover {
          background-color: goldenrod;
        }
      }
      .item:nth-child(3),
      .item:nth-child(5) {
        background-color: rgba(216, 216, 216, 0.5);
        &:hover {
          background-color: goldenrod;
        }
      }
    }
}
</style>
