import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import i18n from '../i18n'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: `/${i18n.locale}`
  },
  {
    path: '/:lang',
    component: {
      render (c) { return c('router-view') }
    },
    beforeEnter: (to, from, next) => {
      const supportedLocales = ['en', 'zh']
      if (!supportedLocales.includes(to.params.lang)) {
        return next('/en') // Redirect to home page with default locale
      }
      i18n.locale = to.params.lang
      return next()
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home
      },
      {
        path: 'services',
        name: 'Services',
        component: () => import('../views/Services.vue')
      },
      {
        path: 'management-team',
        name: 'Management-Team',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "management-team" */ '../views/Management-Team.vue')
      },
      // {
      //   path: 'why-us',
      //   name: 'Why-Us',
      //   component: () => import('../views/Why-Us.vue')
      // },
      {
        path: 'deadline',
        name: 'Deadline',
        component: () => import('../views/Deadline.vue')
      },
      {
        path: 'contact-us',
        name: 'Contact-Us',
        component: () => import('../views/Contact-Us.vue')
      },
      {
        path: 'policy',
        name: 'policy',
        component: () => import('../views/Policy.vue')
      },
      {
        path: 'legal',
        name: 'legal',
        component: () => import('../views/Legal.vue')
      },
      {
        path: '*',
        redirect: to => {
          return { path: `/${i18n.locale}` }
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 } // Scroll to top on route change
  }
})

// Add a global error handler
router.onError((error) => {
  console.error('Router error:', error)
  router.push('/en')
})

export default router
