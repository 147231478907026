<template>
  <div class="about">
    <hero-banner
      :bgPath="`assets/images/hero-banner/leadership_team.png`"
      :bgPathMobile="`assets/images/hero-banner/bulb-mobile.png`"
      height="800"
      horizontal="left"
      dVertical="d-center"
      mVertical="m-top"
      contentCol="8"
      color=""
      shadow="true"
      :title="$t('management-team.banner.title')"
      cta="">
    </hero-banner>

    <container-style class="no-padding-bottom-sm" direction="style-right" bgColor="bg-gradient-grey-from-left" :bgPath="null">
      <b-container>
        <div class="d-none d-lg-block" style="width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.02);
            clip-path: polygon(0% 0%, 23% 120%, 83% 0%);
            position: absolute;
            bottom: 0;
            right: 0;">
        </div>
        <div class="d-lg-none" style="width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.03);
            clip-path: polygon(110% 14%, 0% 46%, 70% 110%);
            position: absolute;
            bottom: 0px;
            right: 0px;">
        </div>
        <b-row class="flex-column flex-lg-row">
          <!-- <b-col class="profile-image col-lg-4 order-1 order-lg-0">
            <img :src="require('@/assets/images/management-team/tan-lih-tyng.png')" alt="">
          </b-col> -->
          <b-col class="profile-image col-lg-4 mb-4 mb-lg-0">
            <div class="profile-bg">
              <div class="profile-card">
                <!-- <div style="width: 100%;
                  height: 100%;
                  background-color: rgb(150 150 150 / 12%);
                  clip-path: polygon(0% 20%, 33% 130%, 125% -14%);
                  position: absolute;
                  bottom: 0;
                  right: 0;">
                </div> -->
                <div class="d-lg-none position-relative">
                  <h4 class="name mb-0 mb-lg-2">LIH TYNG</h4>
                  <div class="title font-weight-bolder">MANAGING PARTNER</div>
                  <div class="title m-0"><div class="d-none d-lg-inline-block">Managing Partner&nbsp;|&nbsp;</div><br class="d-lg-none">Licensed Tax Agent<div class="d-none d-lg-inline-block">&nbsp;|&nbsp;</div><br class="d-lg-none">Chartered Accountant</div>
                </div>
                <img :src="require('@/assets/images/management-team/tan-lih-tyng-v2.png')" alt="">
              </div>
            </div>
          </b-col>
          <b-col class="col-lg-8">
            <div class="d-none d-lg-block">
              <h4 class="name">
                LIH TYNG
                <!-- <a :href="bio.linkedin.path" target="_blank" class=" d-none d-md-inline-block">
                  <fa-icon class="ml-2" :icon="[bio.linkedin.type, bio.linkedin.name]" :color="bio.linkedin.color"/></a> -->
              </h4>
              <h5 class="title mb-4"><div class="d-inline-block d-lg-none">MANAGING PARTNER</div><div class="d-none d-lg-inline-block">Managing Partner&nbsp;|&nbsp;</div><br class="d-lg-none">Licensed Tax Agent | Chartered Accountant</h5>
            </div>
            <div class="pb-5">
              <p class="desc">After completing her bachelor’s degree in accounting at the University of Malaya (UM) and becoming a member of the Malaysian Institute of Accountants (MIA) and the Chartered Tax Institute of Malaysia (CTIM), Lih Tyng became an approved tax agent licensed by the Ministry of Finance (MoF). She is also a trainer accredited by the Malaysia Human Resource Development Corporation (HRD Corp). </p>
              <p class="desc">After accumulating a decade of experience offering premium tax compliance & advisory services for MNCs and conglomerates in industries like property development, hotel chains, food & beverage, manufacturing, oil & gas, and more, Lih Tyng decided to start her boutique firm.</p>
              <p class="desc">With a firm belief that even the smallest businesses should receive the best support, she combines her expertise with friendly dedication to meeting the needs of SMEs like yours.</p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </container-style>

    <container-style class="no-padding-bottom-md" direction="style-left" bgColor="bg-gradient-grey-from-right" :bgPath="null">
      <b-container>
        <div style="width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.02);
          clip-path: polygon(13% 0%, 55% 110%, 110% 30%);
          position: absolute;
          bottom: 0;
          right: 0;">
        </div>
        <b-row class="flex-column flex-lg-row">
          <b-col class="col-lg-8 order-2">
            <div class="d-none d-lg-block">
              <h4 class="name">
                LIH CHEN
                <!-- <a :href="bio.linkedin.path" target="_blank" class=" d-none d-md-inline-block">
                  <fa-icon class="ml-2" :icon="[bio.linkedin.type, bio.linkedin.name]" :color="bio.linkedin.color"/></a> -->
              </h4>
              <h5 class="title mb-4"><div class="d-inline-block d-lg-none">PARTNER</div><div class="d-none d-lg-inline-block">Partner&nbsp;|&nbsp;</div><br class="d-lg-none">Certified Company Secretary | Chartered Accountant</h5>
            </div>
            <div class="pb-5 mb-5 mb-lg-0">
              <p class="desc">Lih Chen holds a bachelor’s degree in accounting from the Northern University of Malaysia (UUM) and is a member of the Malaysian Institute of Accountants (MIA), and a practising company secretary certified by the Companies Commission of Malaysia (SSM).</p>
              <p class="desc">Recognizing that many SME founders face challenges when consulting with several firms to resolve a single issue, Lih Chen sought to break conventions by providing comprehensive compliance & tax solutions in one place.</p>
              <p class="desc">She combines secretarial expertise with advanced efficiency tools, a deep understanding of operations and financials, and a personal touch to offer down-to-earth solutions that you can’t get from larger firms, thereby ensuring startups stay compliant without all the stress, hassle, and confusion.</p>
            </div>
          </b-col>
          <b-col class="profile-image order-1 order-lg-2 mb-4 mb-lg-0">
            <div class="profile-bg">
              <div class="profile-card">
                <div class="d-lg-none position-relative">
                  <h4 class="name mb-0 mb-lg-2">LIH CHEN</h4>
                  <div class="title font-weight-bolder">PARTNER</div>
                  <div class="title mb-lg-4"><div class="d-none d-lg-inline-block">Partner&nbsp;|&nbsp;</div><br class="d-lg-none">Certified Company Secretary<div class="d-none d-lg-inline-block">&nbsp;|&nbsp;</div><br class="d-lg-none">Chartered Accountant</div>
                </div>
                <img :src="require('@/assets/images/management-team/tan-lih-chen-v2.png')" alt="">
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </container-style>

    <!-- <our-team></our-team> -->
    <!-- <div>
      <h1>{{ $t('management-team.title') }}</h1>
      <h3 v-html="$t('management-team.content')" />
    </div> -->
  </div>
</template>

<script>
import heroBannar from '@/components/heroBanner.vue'
// import ourTeam from '@/components/ourTeam.vue'
import containerStyle from '../components/containerStyle.vue'

export default {
  name: 'management-team',
  metaInfo () {
    const title = 'Tyng & Co  |  Leadership Team'
    const description = 'No Business Is Too Small to Have Professional Consultants​'
    document.querySelector('meta[name="description"]').content = description
    return {
      title,
      titleTemplate: null,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: description
        }
      ],
      link: [{
        rel: 'canonical',
        href: `https://tyng-ca.com.my/${this.$i18n.locale}/management-team`
      }]
    }
  },
  components: {
    'hero-banner': heroBannar,
    // 'our-team': ourTeam,
    'container-style': containerStyle
  }
}
</script>

<style lang="scss" scoped>
</style>
