<template>
  <div class="container-style shadow-lg" v-bind:class="[direction, bgColor]"
    :style="backgroundStyle"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'container-style',
  data: function () {
    return {

    }
  },
  props: [
    'direction',
    'bgColor',
    'bgPath'
  ],
  computed: {
    backgroundStyle () {
      return this.bgPath ? { backgroundImage: `url(${require('@/' + this.bgPath)})` } : {}
    }
  },
  created () {
  },
  destroyed () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
