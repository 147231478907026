<template>
    <div>
        <div class="nav-offset"></div>
        <div class="legal mb-5 py-5">
            <div class="container">
                <div v-for="legal in legalListing" :key="legal.id" class="mb-5">
                    <h4 class="mb-4">{{legal.megaTitle}}</h4>
                    <p v-for="copy in legal.bodyCopy" :key="copy.num" v-html="copy.text">
                    </p>
                </div>
            </div>
        </div>
        <div class="py-4 py-lg-5"></div>
    </div>
</template>

<script>
export default {
  name: 'legal',
  data () {
    return {
      legalListing: []
    }
  },
  mounted () {
    this.fetchData(this.$i18n.locale)
  },
  methods: {
    async fetchData () {
      const res = await fetch('../data-lists/legal.json')
      const val = await res.json()
      this.legalListing = val
    }
  }
}
</script>

<style>

</style>
